import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { CHAMBER_DATA } from "../../../constants/constants";
import InputText from "../../../common/forms/InputText";
import SelectOption from "../../../common/forms/SelectOption";
import InputArea from "../../../common/forms/InputArea";
import practice_areas from "../../../common/shared/practice_areas";
import { useDispatch, useSelector } from "react-redux";
import {
  singleUserChamber,
  updateChamber,
} from "../../../redux/actions/chamber_actions";
import Loader from "../../../common/loader/Loader";
import { useParams } from "react-router-dom";
import MultipleOptions from "../../../common/forms/MultipleOptions";
import regions from "../../../common/shared/regions";

const chamberType = [
  { id: "chambers", name: "Chambers" },
  { id: "legal_dept", name: "Legal Department" },
];

function ChamberDetails() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);

  const [formData, setFormData] = useState(CHAMBER_DATA);

  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.chamber);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditLoading(true);
    dispatch(updateChamber(formData)).finally(() => setEditLoading(false));
  };

  useEffect(() => {
    dispatch(singleUserChamber(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  return (
    <Card>
      {loading ? (
        <Loader />
      ) : (
        <form onClick={(e) => handleSubmit(e)}>
          <CardBody>
            <Typography color="blue-gray" variant="h5">
              Chamber Details
            </Typography>

            <div className="grid grid-cols-3 gap-4 my-4">
              <InputText
                defaultValue={formData?.name}
                updateType="name"
                labelTitle="Name"
                updateFormValue={updateFormValue}
                required={true}
                containerStyle="col-span-2"
              />

              <SelectOption
                labelTtile="Select Chamber Type"
                defaultValue={formData?.type}
                updateFormValue={updateFormValue}
                updateType="type"
                children={chamberType}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 my-4">
              <InputText
                defaultValue={formData?.head}
                updateType="head"
                labelTitle="Chamber Head Bar Number"
                updateFormValue={updateFormValue}
                required={true}
              />
              <InputText
                defaultValue={formData?.deputy || ""}
                updateType="deputy"
                labelTitle="Chamber Deputy Bar Number"
                updateFormValue={updateFormValue}
              />
            </div>

            <div className="grid grid-cols-3 gap-4 my-4">
              <InputText
                type="email"
                defaultValue={formData?.email}
                updateType="email"
                labelTitle="Chamber Email Address"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.telephone}
                updateType="telephone"
                labelTitle="Telephone #"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.fax}
                updateType="fax"
                labelTitle="Fax #"
                updateFormValue={updateFormValue}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 my-4">
              <SelectOption
                labelTtile="Select Chamber Region"
                defaultValue={formData?.p_region?.toString() || ""}
                updateFormValue={updateFormValue}
                updateType="p_region"
                children={regions}
              />

              <InputText
                defaultValue={formData.city}
                updateType="city"
                labelTitle="City/Town"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <div className="grid grid-cols-3 gap-4 my-4">
              <InputText
                defaultValue={formData?.postal_add}
                updateType="postal_add"
                labelTitle="Postal Address"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.address}
                updateType="address"
                labelTitle="Street Name/House Number"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.ghpost}
                updateType="ghpost"
                labelTitle="Ghana Post GPS"
                updateFormValue={updateFormValue}
                // required={true}
              />
            </div>

            <div className="grid grid-cols-4 gap-4 my-4">
              <InputText
                defaultValue={formData?.reg_num}
                updateType="reg_num"
                labelTitle="Reg. No. (Act 151, 152, 179)"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.tin_num}
                updateType="tin_num"
                labelTitle="TIN Number "
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.vat_num}
                updateType="vat_num"
                labelTitle="VAT Reg. Number "
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                defaultValue={formData?.ssnit_num}
                updateType="ssnit_num"
                labelTitle="SSNIT Reg. Number"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <div className="grid grid-cols-4 gap-4 my-4">
              <InputText
                type="number"
                defaultValue={formData?.staff_num}
                updateType="staff_num"
                labelTitle="Number of Staff"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="number"
                defaultValue={formData?.clerks_num}
                updateType="clerks_num"
                labelTitle="Number of Clerks"
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="number"
                defaultValue={formData?.accounts_full}
                updateType="accounts_full"
                labelTitle="Full Time Accounts Pers. No."
                updateFormValue={updateFormValue}
                required={true}
              />

              <InputText
                type="number"
                defaultValue={formData?.accounts_part}
                updateType="accounts_part"
                labelTitle="Part Time Accounts Pers. No."
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 my-4">
              <InputArea
                defaultValue={formData?.equipments}
                updateType="equipments"
                labelTitle="Office Equipments"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 my-4">
              <MultipleOptions
                options={practice_areas}
                updateFormValue={updateFormValue}
                select={data.practice_areas}
              />
            </div>

            <div className="mt-20">
              <Typography variant="small" className="text-center">
                By clicking to proceed below you affirm that the information and
                data you have provided on this form are true; and that you are
                not knowingly submitting information which is untrue, misleading
                or false.
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="flex justify-center">
            <Button type="submit" className="flex item-center gap-3">
              {editLoading && <Spinner color="white" />}
              Proceed
            </Button>
          </CardFooter>
        </form>
      )}
    </Card>
  );
}

export default ChamberDetails;
