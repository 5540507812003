import UpdateProfile from "../pages/user/UserProfile";
import Home from "../pages/home/Home";
import Conference from "../pages/conference/Conference";
import License from "../pages/license/License";
import Pupilage from "../pages/pupilage/Pupilage";
import PupilageEdit from "../pages/pupilage/components/PupilageEdit";
import Dues from "../pages/dues/Dues";
import Chambers from "../pages/chambers/Chambers";
import Resources from "../pages/resources/Resources";
import PupilageRequest from "../pages/pupilage/PupilageRequest";
import PupilageAction from "../pages/pupilage/components/PupilageAction";
import Receipt from "../common/shared/Receipt";
import DuesReceipt from "../pages/dues/components/DuesReceipt";
import ChangePassword from "../pages/auth/ChangePassword";
import NewChambers from "../pages/chambers/NewChambers";
import AddChamberMembers from "../pages/chambers/components/AddChamberMembers";
import FilesChamber from "../pages/chambers/components/FilesChamber";
import EditChamber from "../pages/chambers/components/EditChamber";
import GlcChamber from "../pages/glc/GlcChamber";
import GlcPupilage from "../pages/glc/GlcPupilage";
import LicenseCertificate from "../pages/license/components/LicenseCertificate";
import ChamberCertificate from "../pages/chambers/components/ChamberCertificate";
import PupilCert from "../pages/pupilage/components/PupilCert";
import DuesPaymentPage from "../pages/dues/components/DuesPaymentPage";

export const privateRoutes = [
  { path: "/home", element: <Home /> },
  { path: "/home/conferences", element: <Conference /> },
  { path: "/home/license", element: <License /> },
  { path: "/home/license/cert", element: <LicenseCertificate /> },
  { path: "/home/pupilage", element: <Pupilage /> },
  { path: "/home/pupilage/request", element: <PupilageRequest /> },
  { path: "/home/pupilage/action/:id", element: <PupilageAction /> },
  { path: "/home/pupilage/edit", element: <PupilageEdit /> },
  { path: "/home/pupilage/cert", element: <PupilCert /> },
  { path: "/home/dues", element: <Dues /> },
  { path: "/home/dues/receipt/:id", element: <DuesReceipt /> },
  { path: "/home/dues/payment", element: <DuesPaymentPage /> },
  { path: "/home/chamber", element: <Chambers /> },
  { path: "/home/chamber/new", element: <NewChambers /> },
  { path: "/home/chamber/members/:id", element: <AddChamberMembers /> },
  { path: "/home/chamber/files/:id", element: <FilesChamber /> },
  { path: "/home/chamber/edit/:id", element: <EditChamber /> },
  { path: "/home/chamber/certificate", element: <ChamberCertificate /> },
  { path: "/home/resources", element: <Resources /> },
  { path: "/home/receipt/:id", element: <Receipt /> },
  { path: "/home/update", element: <UpdateProfile /> },
  { path: "/home/password", element: <ChangePassword /> },
  { path: "/home/glc/chamber", element: <GlcChamber /> },
  { path: "/home/glc/pupilage", element: <GlcPupilage /> },

  // { path: "/home/sticker", element: <Stickers /> },
];
