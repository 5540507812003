import React from "react";
import { useNavigate } from "react-router-dom";
import checkYear from "../../../utils/yearCheck";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import PupilageIcon from "../../../assets/PupilageIcon";

function PupilageCards({ user }) {
  const navigate = useNavigate();

  const isVisible = checkYear(user.date_of_call);

  return (
    isVisible && (
      <Card
        color="gray"
        className="w-full h-full flex justify-center items-center"
      >
        <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
          <div className="mx-auto mb-6 w-[4rem] h-[4rem]">
            <PupilageIcon />
          </div>
          <Typography variant="h5" color="white">
            Pupillage Request Approval
          </Typography>
          <Typography>View all pupillage request.</Typography>
          <Button
            onClick={() => navigate("/home/pupilage/request")}
            className="mt-10"
            color="white"
            fullWidth
          >
            View
          </Button>
        </CardBody>
      </Card>
    )
  );
}

export default PupilageCards;
