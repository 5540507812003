import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGlcChamber,
  getGlcChamber,
  updateGlcChamber,
  uploadGLCReport,
} from "../../redux/actions/glc_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import RejectIconButton from "../../common/buttons/RejectIconButton";
import AcceptIconButton from "../../common/buttons/AcceptIconButton";
import CalenderIconButton from "../../common/buttons/CalenderIconButton";
import UploadIconButton from "../../common/buttons/UploadIconButton";
import DataTable from "../../common/shared/DataTable";
import FilesIconButton from "../../common/buttons/FilesIconButton";
import { CHAMBER_DATA } from "../../constants/constants";
import OpenModal from "../../common/actions/OpenModal";
import { Typography } from "@material-tailwind/react";
import DatePickerComp from "../../common/forms/DatePicker";
import FileUpload from "../../common/forms/FileUpload";
import InputArea from "../../common/forms/InputArea";
import DeleteIconButton from "../../common/buttons/DeleteIconButton";

const link = [{ name: "Chamber Application", link: "" }];

function GlcChamber() {
  const [loading, setLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [filesModal, setFilesModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [formData, setFormData] = useState(CHAMBER_DATA);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();

  const { data, glcError, glcSuccess } = useSelector((state) => state.glc);

  const currentDate = new Date();
  let nextDay = currentDate.setDate(currentDate.getDate() + 1);

  const columns = [
    {
      field: "name",
      headerName: "Chamber Name",
      flex: 1,
      valueGetter: (params) => params.row.name.toUpperCase(),
    },
    {
      field: "head",
      headerName: "Head",
      flex: 1,
      valueGetter: (params) =>
        params.row.headOf.first_name +
        " " +
        params.row.headOf.last_name +
        " - " +
        params.row.headOf.bar_number,
    },
    {
      field: "status",
      headerName: "Stage",
      flex: 1,
      valueGetter: (params) => params.row.chamber_status.name,
    },
    { field: "createdAt", headerName: "Date", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <div>
          {params.row.stage === 1 ? (
            <AcceptIconButton onClick={() => confirmApprove(params.row)} />
          ) : params.row.stage === 2 && !params.row.inspect_date ? (
            <CalenderIconButton onClick={() => showDate(params.row)} />
          ) : params.row.stage === 2 && params.row.inspect_date ? (
            <UploadIconButton onClick={() => openUpload(params.row)} />
          ) : params.row.stage === 3 ? (
            <AcceptIconButton onClick={() => confirmApprove(params.row)} />
          ) : null}
          {params.row.stage !== 5 && (
            <>
              <FilesIconButton onClick={() => showFiles(params.row)} />
              {params.row.stage === 4 ? (
                <DeleteIconButton onClick={() => showDelete(params.row)} />
              ) : (
                <RejectIconButton onClick={() => openReject(params.row)} />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const confirmApprove = (item) => {
    setConfirmModal(!confirmModal);
    setFormData(item);
  };

  const showDate = (item) => {
    setDateModal(!dateModal);
    setFormData(item);
  };

  const showDelete = (item) => {
    setDeleteModal(!deleteModal);
    setFormData(item);
  };

  const showFiles = (item) => {
    setFilesModal(!filesModal);
    setFormData(item);
  };

  const openUpload = (item) => {
    setFileUpload(!fileUpload);
    setFormData(item);
  };

  const openReject = (item) => {
    setFormData(item);
    setRejectModal(true);
  };

  const handleApprove = () => {
    setApproveLoading(true);
    const approveData = {
      id: formData.id,
      stage: ++formData.stage,
    };
    dispatch(updateGlcChamber(approveData)).finally(() => {
      setConfirmModal(false);
      setApproveLoading(false);
    });
  };

  const handleDateInspection = () => {
    setDateLoading(true);
    const dateData = {
      id: formData.id,
      inspect_date: selectedDate.toISOString(),
    };
    dispatch(updateGlcChamber(dateData)).finally(() => {
      setDateModal(false);
      setDateLoading(false);
    });
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    dispatch(deleteGlcChamber(formData.id)).finally(() => {
      setDeleteModal(false);
      setDeleteLoading(false);
    });
  };

  const handleFileUpload = async (file) => {
    setFileLoading(true);
    const id = formData.id;
    dispatch(uploadGLCReport(id, file)).finally(() => {
      setFileUpload(!fileUpload);
      setFileLoading(false);
    });
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRejectLoading(true);
    const rejectData = {
      id: formData.id,
      rejected: new Date(),
      rejected_note: formData.rejected_note,
    };
    dispatch(updateGlcChamber(rejectData)).finally(() => {
      setRejectModal(false);
      setRejectLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getGlcChamber()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle
        title="Chamber Application"
        description=""
        children={link}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {glcError ? (
          <AlertMessage type="error" message={glcError} />
        ) : glcSuccess ? (
          <AlertMessage type="success" message={glcSuccess} />
        ) : null}

        {loading ? (
          <Loader />
        ) : (
          <>
            <DataTable
              rows={data}
              columns={columns}
              dateFormat={["createdAt"]}
            />

            {confirmModal && (
              <OpenModal
                open={confirmModal}
                handler={confirmApprove}
                title="Confirm Approval"
                loading={approveLoading}
                actionTitle="Confirm"
                handleSubmit={(e) => handleApprove(e)}
                children={
                  <Typography color="black" variant="paragraph">
                    Please confirm you want to approve{" "}
                    <strong>{formData.name}</strong> application?
                  </Typography>
                }
              />
            )}

            {dateModal && (
              <OpenModal
                open={dateModal}
                handler={showDate}
                title="Inpection Date"
                loading={dateLoading}
                actionTitle="Save Date"
                handleSubmit={handleDateInspection}
                children={
                  <DatePickerComp
                    label=""
                    selectedDate={selectedDate}
                    onSelectDate={handleDateChange}
                    minDate={nextDay}
                  />
                }
              />
            )}

            {fileUpload && (
              <OpenModal
                open={fileUpload}
                handler={openUpload}
                title="Upload Report"
                loading={fileLoading}
                actionTitle=""
                // handleSubmit={() => setFileUpload(!fileUpload)}
                children={
                  <FileUpload
                    label="Report"
                    onUpload={(file) => handleFileUpload(file)}
                    loading={fileLoading}
                  />
                }
              />
            )}

            {filesModal && (
              <OpenModal
                open={filesModal}
                handler={showFiles}
                title="Uploaded Documemnts"
                children={
                  <>
                    {formData.files.reg && (
                      <div className="mb-6">
                        <Typography color="black" variant="small">
                          Registration Document
                        </Typography>
                        <a
                          href={formData.files.reg}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formData.files.reg}
                        </a>
                      </div>
                    )}

                    {formData.files.vat && (
                      <div className="mb-6">
                        <Typography color="black" variant="small">
                          VAT Document
                        </Typography>
                        <a
                          href={formData.files.vat}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formData.files.vat}
                        </a>
                      </div>
                    )}

                    {formData.files.ssnit && (
                      <div className="mb-6">
                        <Typography color="black" variant="small">
                          SSNIT Document
                        </Typography>
                        <a
                          href={formData.files.ssnit}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formData.files.ssnit}
                        </a>
                      </div>
                    )}

                    {formData.files.evidence && (
                      <div className="mb-6">
                        <Typography color="black" variant="small">
                          Evidence of Client's Account
                        </Typography>
                        <a
                          href={formData.files.evidence}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formData.files.evidence}
                        </a>
                      </div>
                    )}

                    <hr className="my-4" />

                    {formData.reports ? (
                      <div className="mb-6">
                        <Typography color="black" variant="small">
                          Report
                        </Typography>
                        <a
                          href={formData.reports}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formData.reports}
                        </a>
                      </div>
                    ) : (
                      <Typography color="black" variant="lead">
                        No Report Uploaded
                      </Typography>
                    )}
                  </>
                }
              />
            )}

            {rejectModal && (
              <OpenModal
                open={rejectModal}
                handler={openReject}
                title="Reject Application"
                loading={rejectLoading}
                actionTitle="Proceed"
                handleSubmit={(e) => handleReject(e)}
                children={
                  <InputArea
                    defaultValue={formData?.rejected_note}
                    updateType="rejected_note"
                    labelTitle="Reason"
                    updateFormValue={updateFormValue}
                    required={true}
                  />
                }
              />
            )}

            {deleteModal && (
              <OpenModal
                open={deleteModal}
                handler={showDelete}
                title="Confirm Deletion"
                loading={deleteLoading}
                actionTitle="Delete"
                children={
                  <Typography color="black" variant="paragraph">
                    Please confirm you want to delete{" "}
                    <strong>{formData.name}</strong> ?
                  </Typography>
                }
                handleSubmit={(e) => handleDelete(e)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default GlcChamber;
