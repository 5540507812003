import { Button, Input, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import ChangeButton from "../buttons/ChangeButton";

function FileUpload({ label, onUpload, helperText, checked }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);

  useEffect(() => {
    if (checked) {
      setIsUploading(false);
      setIsDisabled(true);
    }
  }, [checked]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setIsDisabled(false);
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      onUpload(formData).finally(() => {
        setIsUploading(false);
        setIsDisabled(true);
      });
    }
  };

  const handleFileReset = () => {
    document.getElementById(`${label}-file-input`).value = "";
    setSelectedFile(null);
    setIsDisabled(false);
    setFileChanged(false);
  };

  return (
    <div className="relative flex flex-col w-full">
      <form encType="multipart/form-data">
        <Input
          color="black"
          type="file"
          label={label}
          variant="static"
          className="pr-20"
          onChange={handleFileChange}
          containerProps={{
            className: "min-w-0",
          }}
          disabled={isDisabled}
          id={`${label}-file-input`}
        />

        {helperText && (
          <Typography
            variant="small"
            color="black"
            className="mt-2 flex items-center gap-1 font-normal"
          >
            {helperText}
          </Typography>
        )}

        {isDisabled ? (
          <ChangeButton
            customClass="!absolute right-1 top-1"
            onClick={handleFileReset}
          />
        ) : (
          <Button
            size="sm"
            color="blue-gray"
            className="!absolute right-1 top-1 rounded"
            type="button"
            disabled={!selectedFile}
            onClick={handleUpload}
          >
            {isUploading ? <Spinner className="h-3 w-3" /> : "Upload"}
          </Button>
        )}
      </form>
    </div>
  );
}

export default FileUpload;
