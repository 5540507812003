import { handleApi } from "../utils";
import {
  GET_DUES,
  DUES_PAYMENT,
  NORMAL_DUES,
  UPDATE_DUES_PAYMENT,
  VERIFY_DUES_PAYMENT,
  FAILED_DUES_PAYMENT,
  SECOND_DUES_VERIFY,
  SECOND_DUES_FAIL,
} from "../../constants/api_constants";

export const getUserDues = async () => {
  return handleApi("get", GET_DUES);
};

export const duesPayment = async (values) => {
  return handleApi("post", DUES_PAYMENT, values);
};

export const normalDues = async (values) => {
  return handleApi("post", NORMAL_DUES, values);
};

export const udpateReceipt = async (values) => {
  return handleApi("put", `${UPDATE_DUES_PAYMENT}/${values.id}`, values);
};

export const verifyDuesPayment = async (values) => {
  return handleApi("put", VERIFY_DUES_PAYMENT, values);
};

export const failedDuesPayment = async (values) => {
  return handleApi("put", FAILED_DUES_PAYMENT, values);
};

export const secondDuesVerify = async (values) => {
  return handleApi("put", SECOND_DUES_VERIFY, values);
};

export const secondDuesFail = async (values) => {
  return handleApi("put", SECOND_DUES_FAIL, values);
};
