import createAsyncAction from "../../utils/asyncAction";

import * as api from "../api/chamber/chamber_api";
import * as types from "../constants/chamber_constants";

export const applyChamber = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.applyChamber(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.APPLY_CHAMBER_FAILURE, payload: error });
    } else {
      const { chamber } = data;
      dispatch({ type: types.APPLY_CHAMBER_SUCCESS, payload: chamber });
      navigate(`/home/chamber/members/${chamber.id}`);
    }
  } catch (error) {
    dispatch({
      type: types.APPLY_CHAMBER_FAILURE,
      payload: "No Connection",
    });
  }
};

const uploadFileAction =
  (fileType, id, formData, successType, failureType) => async (dispatch) => {
    try {
      const response = await api[`upload${fileType}`](id, formData);
      const { error, data } = response;

      if (error) {
        dispatch({ type: failureType, payload: error });
      } else {
        dispatch({ type: successType, payload: data });
      }
    } catch (error) {
      dispatch({
        type: failureType,
        payload: error,
      });
    }
  };

export const updateChamber = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.updateChamber(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.UPDATE_CHAMBER_FAILURE, payload: error });
    } else {
      dispatch({ type: types.UPDATE_CHAMBER_SUCCESS, payload: data });
      navigate("/home/chamber");
    }
  } catch (error) {
    dispatch({
      type: types.UPDATE_CHAMBER_FAILURE,
      payload: error.message,
    });
  }
};

export const getChamberCert = (formData) => async (dispatch) => {
  try {
    await api.chamberCert(formData);
  } catch (error) {
    dispatch({ type: types.GET_USER_CHAMBER_FAILURE, payload: error });
  }
};

const getUserChamberTypes = {
  success: types.GET_USER_CHAMBER_SUCCESS,
  failure: types.GET_USER_CHAMBER_FAILURE,
};

const chamberPaymentTypes = {
  success: types.CHAMBER_PAYMENT_SUCCESS,
  failure: types.CHAMBER_PAYMENT_FAILURE,
};

const manualChamberTypes = {
  success: types.MANUAL_CHAMBER_SUCCESS,
  failure: types.MANUAL_CHAMBER_FAILURE,
};

const getSingleUserChamberTypes = {
  success: types.SINGLE_USER_CHAMBER_SUCCESS,
  failure: types.SINGLE_USER_CHAMBER_FAILURE,
};

const getUserChamberMemberTypes = {
  success: types.GET_CHAMBER_MEMBER_SUCCESS,
  failure: types.GET_CHAMBER_MEMBER_FAILURE,
};

const addUserChamberMemberTypes = {
  success: types.ADD_CHAMBER_MEMBER_SUCCESS,
  failure: types.ADD_CHAMBER_MEMBER_FAILURE,
};

const removeUserChamberMemberTypes = {
  success: types.REMOVE_CHAMBER_MEMBER_SUCCESS,
  failure: types.REMOVE_CHAMBER_MEMBER_FAILURE,
};

const verifyChamberPaymentTypes = {
  success: types.VERIFY_CHAMBER_SUCCESS,
  failure: types.VERIFY_CHAMBER_FAILURE,
};

const failedChamberPaymentTypes = {
  success: types.FAILED_CHAMBER_SUCCESS,
  failure: types.FAILED_CHAMBER_FAILURE,
};

const secondChamberVerifyTypes = {
  success: types.SECOND_CHAMBER_VERIFY_SUCCESS,
  failure: types.SECOND_CHAMBER_VERIFY_FAILURE,
};

const secondChamberFailTypes = {
  success: types.SECOND_CHAMBER_FAIL_SUCCESS,
  failure: types.SECOND_CHAMBER_FAIL_FAILURE,
};

export const getUserChamber = createAsyncAction(
  api.getUserChamber,
  getUserChamberTypes
);

export const getChamberMember = createAsyncAction(
  api.getChamberMember,
  getUserChamberMemberTypes
);

export const addChamberMember = createAsyncAction(
  api.addChamberMember,
  addUserChamberMemberTypes
);

export const removeChamberMember = createAsyncAction(
  api.removeChamberMember,
  removeUserChamberMemberTypes
);

export const chamberPayment = createAsyncAction(
  api.chamberPayment,
  chamberPaymentTypes
);

export const manualChamber = createAsyncAction(
  api.manualChamber,
  manualChamberTypes
);

export const verifyChamberPayment = createAsyncAction(
  api.verifyChamberPayment,
  verifyChamberPaymentTypes
);

export const failedChamberPayment = createAsyncAction(
  api.failedChamberPayment,
  failedChamberPaymentTypes
);

export const secondChamberVerify = createAsyncAction(
  api.secondChamberVerify,
  secondChamberVerifyTypes
);

export const seondChamberFaile = createAsyncAction(
  api.secondChamberFail,
  secondChamberFailTypes
);

export const uploadRGDFile = (id, formData) =>
  uploadFileAction(
    "RGD",
    id,
    formData,
    types.UPLOAD_CHAMBER_RGD_SUCCESS,
    types.UPLOAD_CHAMBER_RGD_FAILURE
  );

export const uploadVATFile = (id, formData) =>
  uploadFileAction(
    "VAT",
    id,
    formData,
    types.UPLOAD_CHAMBER_VAT_SUCCESS,
    types.UPLOAD_CHAMBER_VAT_FAILURE
  );

export const uploadSSNITFile = (id, formData) =>
  uploadFileAction(
    "SSNIT",
    id,
    formData,
    types.UPLOAD_CHAMBER_SSNIT_SUCCESS,
    types.UPLOAD_CHAMBER_SSNIT_FAILURE
  );

export const uploadEvidence = (id, formData) =>
  uploadFileAction(
    "Evidence",
    id,
    formData,
    types.UPLOAD_CHAMBER_EVIDENCE_SUCCESS,
    types.UPLOAD_CHAMBER_EVIDENCE_FAILURE
  );

export const singleUserChamber = createAsyncAction(
  api.singleUserChamber,
  getSingleUserChamberTypes
);
