import React, { useEffect, useState } from "react";
import { USER_DATA } from "../../../constants/constants";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import InputText from "../../../common/forms/InputText";
import regions from "../../../common/shared/regions";
import SelectOption from "../../../common/forms/SelectOption";
import DatePickerComp from "../../../common/forms/DatePicker";
import practice_areas from "../../../common/shared/practice_areas";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  updateProfileAction,
} from "../../../redux/actions/auth_action";
import Loader from "../../../common/loader/Loader";
import MultipleOptions from "../../../common/forms/MultipleOptions";
import { useNavigate } from "react-router-dom";

const gender = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
];

function UpdateForm() {
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);

  const [formData, setFormData] = useState(USER_DATA);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const userData = useSelector((state) => state.auth.profile);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, date_of_birth: date.toISOString() });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setEditLoading(true);
    dispatch(updateProfileAction(formData, naviagte)).finally(() =>
      setEditLoading(false)
    );
  };

  useEffect(() => {
    dispatch(getProfile()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      setFormData(userData);
      if (userData.date_of_birth !== null) {
        setSelectedDate(userData.date_of_birth);
      }
    }
  }, [userData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card>
            <form onSubmit={(e) => handleUpdate(e)}>
              <CardBody>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
                  <InputText
                    defaultValue={formData?.username || ""}
                    updateType="username"
                    labelTitle="Username"
                    updateFormValue={updateFormValue}
                    required={true}
                    containerStyle="mt-6"
                  />
                  <InputText
                    type="email"
                    defaultValue={formData?.email || ""}
                    updateType="email"
                    labelTitle="Email Address"
                    updateFormValue={updateFormValue}
                    required={true}
                    containerStyle="mt-6"
                  />
                  <DatePickerComp
                    label="Date of Birth"
                    selectedDate={selectedDate}
                    onSelectDate={handleDateChange}
                    defaultDate={formData?.date_of_birth}
                  />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
                  <InputText
                    defaultValue={formData?.phone || ""}
                    updateType="phone"
                    labelTitle="Phone #"
                    updateFormValue={updateFormValue}
                    required={true}
                  />

                  <InputText
                    defaultValue={formData?.phone_sec || ""}
                    updateType="phone_sec"
                    labelTitle="Phone Alt#"
                    updateFormValue={updateFormValue}
                  />

                  <SelectOption
                    labelTtile="Select Gender"
                    defaultValue={formData?.gender || ""}
                    updateFormValue={updateFormValue}
                    updateType="gender"
                    children={gender}
                  />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
                  <SelectOption
                    labelTtile="Select Practice Region"
                    defaultValue={formData?.p_region?.toString() || ""}
                    updateFormValue={updateFormValue}
                    updateType="p_region"
                    children={regions}
                  />

                  <SelectOption
                    labelTtile="Select Primary Region (Optional)"
                    defaultValue={formData?.primary_region?.toString() || ""}
                    updateFormValue={updateFormValue}
                    updateType="primary_region"
                    children={regions}
                  />

                  <SelectOption
                    labelTtile="Select Secondary Region (Optional)"
                    defaultValue={formData?.secondary_region?.toString() || ""}
                    updateFormValue={updateFormValue}
                    updateType="secondary_region"
                    children={regions}
                  />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-">
                  <InputText
                    defaultValue={formData?.chamber_name || ""}
                    updateType="chamber_name"
                    labelTitle="Chamber Name"
                    updateFormValue={updateFormValue}
                    required={true}
                  />

                  <InputText
                    defaultValue={formData?.chamber_address || ""}
                    updateType="chamber_address"
                    labelTitle="Chamber Address"
                    updateFormValue={updateFormValue}
                  />
                </div>

                <div className="flex flex-col sm:flex-row gap-4 my-6">
                  <div className="flex-grow">
                    <InputText
                      defaultValue={formData.academic_q || ""}
                      updateType="academic_q"
                      labelTitle="Academic Qualification"
                      updateFormValue={updateFormValue}
                    />
                  </div>

                  <div className="flex-none">
                    <InputText
                      defaultValue={formData.tin || ""}
                      updateType="tin"
                      labelTitle="Tin #"
                      updateFormValue={updateFormValue}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 my-4">
                  <MultipleOptions
                    options={practice_areas}
                    updateFormValue={updateFormValue}
                    select={userData.practice_areas}
                  />
                </div>

                <div className="mt-20">
                  <Typography
                    color="pink"
                    variant="small"
                    className="text-center"
                  >
                    Please provide relevant information such as your primary
                    active email, phone number, region of practice etc. in order
                    use the portal
                  </Typography>
                  <Typography variant="small" className="text-center">
                    By clicking to proceed below you affirm that the information
                    and data you have provided on this form are true; and that
                    you are not knowingly submitting information which is
                    untrue, misleading or false.
                  </Typography>
                </div>
              </CardBody>
              <CardFooter className="flex justify-center">
                <Button type="submit" className="flex item-center gap-3">
                  {editLoading ? <Spinner color="white" /> : "Update Details"}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </>
      )}
    </>
  );
}

export default UpdateForm;
