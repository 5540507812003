import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import menuItems from "./MenuItems";
import PupilageCards from "./PupilageCards";
import { useSelector } from "react-redux";
import GLCChamberCard from "./GLCChamber";
import GLCPupilage from "./GLCPupilage";
import menuSections from "./MenuSection";
import Divider from "../../../common/shared/Divider";
import GLCPupilageCard from "./GLCPupilage";
import GLCSection from "./GlcSection";
import checkYear from "../../../utils/yearCheck";

function CardItem({ path, name, description, btn, icon }) {
  const navigate = useNavigate();

  return (
    <Card className="w-full h-full flex justify-center items-center">
      <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
        <img className="mx-auto mb-6 w-[4rem] h-[4rem]" src={icon} alt={name} />
        <Typography variant="h5" color="blue-gray">
          {name}
        </Typography>
        <Typography>{description}</Typography>
        <Button onClick={() => navigate(path)} fullWidth className="mt-10">
          {btn}
        </Button>
      </CardBody>
    </Card>
  );
}

function ServiceCard() {
  const user = useSelector((state) => state.auth?.userData);

  return (
    <div className="max-w-[1200px] m-auto px-4">
      {user.isGLC &&
        GLCSection.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <Divider title={section.title} color={section.color} variant="h4" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
              {section.menu.map((menuItem, menuItemIndex) => (
                <CardItem key={menuItemIndex} {...menuItem} />
              ))}
            </div>
          </div>
        ))}

      {checkYear(user.date_of_call) && (
        <div>
          <Divider title="Applications" color="blue" variant="h4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
            <PupilageCards user={user} />
          </div>
        </div>
      )}

      {menuSections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <Divider title={section.title} color={section.color} variant="h4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
            {section.menu.map((menuItem, menuItemIndex) => (
              <CardItem key={menuItemIndex} {...menuItem} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServiceCard;
