import React, { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { updateDues } from "../../../redux/actions/dues_action";
import { Button } from "@material-tailwind/react";

function UpdatePaymentButton({ id, amount, data }) {
  const [reference, setReference] = useState(new Date().getTime().toString());

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.userData);

  const oldReceipt = data?.receipts?.find((item) => item.post_type === "dues");

  const newAmount = parseInt(data.amount) - parseInt(oldReceipt.amount);

  const config = {
    reference: reference || new Date().getTime().toString(),
    email: user?.email,
    amount: newAmount * 100 + newAmount * 0.02 * 100,
    publicKey: "pk_live_68dddf36b26bb406911598f937c7a6b4071765e5",
    currency: "GHS",
    metadata: {
      section_id: id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const onSuccess = (reference) => {
    const formData = { amount: newAmount, id: oldReceipt.id };

    dispatch(updateDues(formData)).finally(() => {
      setReference(new Date().getTime().toString());
    });
  };

  const onClose = () => {
    setReference(new Date().getTime().toString());
  };

  const initializePayment = usePaystackPayment(config);
  return (
    <Button color="pink" onClick={() => initializePayment(onSuccess, onClose)}>
      Make Payment
    </Button>
  );
}

export default UpdatePaymentButton;
