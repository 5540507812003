import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SectionTitle from "../../common/shared/SectionTitle";
import Loader from "../../common/loader/Loader";
import AlertMessage from "../../common/actions/AlertMessage";
import NoContent from "../../common/shared/NoContent";
import ConferenceList from "./components/ConferenceList";

import { getConference } from "../../redux/actions/conference_action";

const link = [{ name: "Conferences", link: "" }];

function Conference() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, conferenceError } = useSelector((state) => state.conference);

  useEffect(() => {
    dispatch(getConference()).finally(() => {
      setLoading(false);
    });
  }, [dispatch, conferenceError]);

  return (
    <>
      <SectionTitle
        title="Conference"
        children={link}
        description="Note that you have to be in Good Financial Standing in order to Register for any Conference."
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {conferenceError && (
          <AlertMessage type="error" message={conferenceError} />
        )}

        {loading ? (
          <Loader />
        ) : data.conference?.length === 0 ? (
          <NoContent message="No Conference Available Yet." />
        ) : (
          <ConferenceList row={data?.conference} />
        )}
      </div>
    </>
  );
}

export default Conference;
