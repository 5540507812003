const regions = [
  { id: 1, name: "Greater Accra", abbr: "GAR" },
  { id: 2, name: "Ashanti", abbr: "ASH" },
  { id: 3, name: "Central", abbr: "CEN" },
  { id: 4, name: "Volta & Oti", abbr: "VOL" },
  { id: 5, name: "Bono, Bono East & Ahafo", abbr: "BAR" },
  { id: 6, name: "Eastern", abbr: "ESR" },
  { id: 7, name: "Western & Western North", abbr: "WSR" },
  { id: 8, name: "Northern, Savannah & North East", abbr: "NOR" },
  { id: 9, name: "Upper West", abbr: "UWR" },
  { id: 10, name: "Upper East", abbr: "UER" },
];

export default regions;
