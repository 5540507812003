import React, { useCallback, useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import {
  chamberPayment,
  failedChamberPayment,
  verifyChamberPayment,
} from "../../../redux/actions/chamber_actions";
import { Button, Spinner } from "@material-tailwind/react";
import axios from "axios";

function ChamberPayment({ data }) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newManual, setNewManual] = useState({});
  const [ref, setRef] = useState("");

  const user = JSON.parse(localStorage.getItem("profile")).user;
  const { manual } = useSelector((state) => state.chamber);

  const dispatch = useDispatch();

  const handleGenerate = useCallback(() => {
    setRef(user.bar_number + "-" + new Date().getTime().toString());
  }, [setRef, user]);

  const config = {
    reference: ref,
    email: user?.email,
    amount: data.exp_date
      ? data.product.meta.renewal * 100 + data.product.meta.renewal * 100 * 0.02
      : data.product.meta.application * 100 +
        data.product.meta.renewal * 100 * 0.02,
    publicKey: "pk_live_64596e5510aaf2f6e0aafebc5351e4a34aa01889",
    currency: "GHS",
    metadata: {
      section_id: data.id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const baseURL = "https://api.paystack.co";
    const axiosInstance = axios.create({ baseURL });
    const referenceId = reference.reference;

    const endpoint = `/transaction/verify/${referenceId}`;
    const method = "GET";

    axiosInstance({
      method,
      url: endpoint,
      headers: {
        Authorization:
          "Bearer sk_live_dae9b6a7960f00372df59df8fc55cfb034ef2ece",
      },
    })
      .then((response) => {
        const sendData = {
          post_type: "chambers",
          year: data.year,
          id: data?.id,
        };

        if (response.data.status) {
          dispatch(verifyChamberPayment(sendData)).finally(() =>
            setLiveLoading(false)
          );
        } else {
          const sendData = {
            reference: referenceId,
            year: data?.year,
            id: data.id,
          };
          dispatch(failedChamberPayment(sendData)).finally(() =>
            setLiveLoading(false)
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const onClose = () => {
    setLiveLoading(false);
    handleGenerate();
  };

  const handleLive = (e) => {
    e.preventDefault();

    setLiveLoading(true);
    const formData = {
      id: data.id,
      productId: data.product.id,
      year: data.product.year,
      amount: data.renewal
        ? data.product.meta.renewal
        : data.product.meta.application,
      reference: ref,
    };

    dispatch(chamberPayment(formData)).finally(() => {
      if (newManual) {
        initializePayment(onSuccess, onClose);
      }
    });
  };

  useEffect(() => {
    handleGenerate();
  }, [handleGenerate]);

  useEffect(() => {
    if (manual) {
      setNewManual(manual.receipt);
    }
  }, [manual]);

  return (
    <Button color="pink" onClick={(e) => handleLive(e)}>
      {liveLoading ? <Spinner color="white" /> : "Make Payment"}
    </Button>
  );
}

export default ChamberPayment;
