import React from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { Link } from "react-router-dom";
import FolderComp from "../../assets/FolderComp";
import resourceItem from "./components/resourceItems";

const link = [{ name: "Resources", link: "" }];

function Resources() {
  return (
    <>
      <SectionTitle
        title="Resources"
        description=""
        children={link}
        link={"/home"}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        <div className="w-full rounded-lg flex justify-center">
          <div className="m-auto w-full grid grid-cols-2 lg:grid-cols-5 justify-center items-center gap-10">
            {resourceItem.map((item, index) => (
              <Link to={item.link} target="_blank" key={index}>
                <div className="flex justify-center items-center">
                  <div className="lg:w-[10rem] text-center flex flex-col items-center">
                    <div>
                      <FolderComp />
                    </div>
                    <p className="text-sm">{item.name}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
