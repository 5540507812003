import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Option,
  Select,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { PUPILAGE_DATA } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addPupilage,
  checkPupilage,
} from "../../../redux/actions/pupilage_action";
import InputText from "../../../common/forms/InputText";
import regions from "../../../common/shared/regions";
import DatePickerComp from "../../../common/forms/DatePicker";
import PupilgeDialog from "./PupilgeDialog";
import moment from "moment";

function PupilageForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(PUPILAGE_DATA);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const { checkData } = useSelector((state) => state.pupilage);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({ ...formData, start_date: date.toISOString() });
  };

  const handleSelectChange = (value) => {
    setFormData({ ...formData, p_region: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenModal(false);
    setLoading(true);

    await dispatch(checkPupilage(formData)).finally(() => {
      setLoading(false);
      setOpenModal(true);
    });
  };

  const handlePupilage = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(addPupilage(formData)).finally(() => {
      setLoading(false);
      setOpenModal(true);
      window.location.reload();
    });
  };

  return (
    <Card>
      <form onSubmit={(e) => handleSubmit(e)}>
        <CardBody>
          <Typography color="blue-gray" variant="h5">
            Pupillage Form
          </Typography>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
            <InputText
              type="text"
              defaultValue={formData?.s_bar_number}
              updateType="s_bar_number"
              labelTitle="Senior Bar Number"
              updateFormValue={updateFormValue}
              required={true}
            />

            <InputText
              type="text"
              defaultValue={formData?.chamber_num}
              updateType="chamber_num"
              labelTitle="Chambers / Legal Dept. Number"
              updateFormValue={updateFormValue}
              required={true}
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <div className="mt-6">
              <Select
                size="lg"
                label="Select Practice Region"
                onChange={handleSelectChange}
              >
                {regions.map((region, index) => (
                  <Option key={index} value={`${region.id}`}>
                    {region.name}
                  </Option>
                ))}
              </Select>
            </div>

            <DatePickerComp
              label="Start Date"
              selectedDate={selectedDate}
              onSelectDate={handleDateChange}
              maxDate={new Date()}
            />
          </div>
          <div className="mt-20">
            <Typography variant="small" className="text-center">
              By clicking to proceed below you affirm that the information and
              data you have provided on this form are true; and that you are not
              knowingly submitting information which is untrue, misleading or
              false.
            </Typography>
          </div>
        </CardBody>
        <CardFooter className="flex justify-center">
          <Button type="submit" className="flex items-center gap-3">
            {loading && <Spinner color="white" />}
            Submit Application
          </Button>
        </CardFooter>
      </form>

      {openModal && checkData && (
        <PupilgeDialog
          modal={openModal}
          senior={
            checkData?.pupil?.title +
            " " +
            checkData?.pupil?.first_name +
            " " +
            checkData?.pupil?.last_name
          }
          chamber={checkData?.pupil?.name}
          region={regions[formData?.p_region - 1].name + " Region"}
          fxn={(e) => handlePupilage(e)}
          start={moment(formData.start_date).format("MMMM Do YYYY")}
          loading={loading}
        />
      )}
    </Card>
  );
}

export default PupilageForm;
