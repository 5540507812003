import { Chip } from "@material-tailwind/react";
import React from "react";

function ConferenceStatusTag({ data }) {
  return (
    <div className="flex ml-2">
      {data.exempt ? (
        <Chip color="indigo" variant="ghost" value="Exempted" />
      ) : data.receiptStatus?.status === 0 ? (
        <Chip color="light-blue" variant="ghost" value="Registered. Not Paid" />
      ) : data.receiptStatus?.status > 0 ? (
        <Chip color="green" variant="ghost" value="Paid" />
      ) : (
        <Chip color="amber" variant="ghost" value="Not Registered" />
      )}
    </div>
  );
}

export default ConferenceStatusTag;
