import * as types from "../constants/payment_constants";

const initialState = {
  data: null,
  receiptError: null,
};

const receiptReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_RECEIPT_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.GET_RECEIPT_FAILURE:
      return { ...state, receiptError: payload ? payload : null };

    default:
      return state;
  }
};

export default receiptReducer;
