import React from "react";
import ReceiptButton from "../../../common/buttons/ReceiptButton";
import LicensePayment from "./LicensePayment";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";

function LicenseButtons({ data }) {
  const navigate = useNavigate();

  const formData = {
    year: data?.year,
    cert_number: data?.receipt?.cert_number,
    date_paid: data?.receipt?.date_paid,
  };

  const handleCertGenerate = () => {
    navigate("/home/license/cert", { state: { formData } });
  };

  return data?.receipt?.status === 1 ? (
    <div className="flex items-center gap-3 justify-between">
      <ReceiptButton
        disabled={
          data?.standing
            ? !data.standing || !data.pupilStatus || !data.isRegion
            : null
        }
        id={data?.receipt?.id}
        text="View Receipt"
      />
      <Button
        disabled={
          data?.standing
            ? !data.standing || !data.pupilStatus || !data.isRegion
            : null
        }
        color="indigo"
        fullWidth
        onClick={() => handleCertGenerate()}
      >
        View Cert
      </Button>
    </div>
  ) : (
    <LicensePayment data={data} />
  );
}

export default LicenseButtons;
