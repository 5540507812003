import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userDues } from "../../redux/actions/dues_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import DuesList from "./components/DuesList";
import NoContent from "../../common/shared/NoContent";

const link = [{ name: "Dues Payment", link: "" }];

function Dues() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, duesError } = useSelector((state) => state.dues);

  useEffect(() => {
    dispatch(userDues()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <>
      <SectionTitle
        title="Dues Payment"
        description="Please note that you have to pay for previous year's dues before paying for the next."
        children={link}
        link={"/home"}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {duesError && <AlertMessage type="error" message={duesError} />}

        {loading ? (
          <Loader />
        ) : data.dues.length === 0 ? (
          <NoContent message="No Dues Data Yet." />
        ) : (
          <DuesList data={data.dues} />
        )}
      </div>
    </>
  );
}

export default Dues;
