export default function padWithZero(item) {
  if (!item) {
    return "";
  }

  const desiredLength = 5;
  const itemString = String(item);

  if (itemString.length >= desiredLength) {
    return itemString;
  } else {
    const zeroToPad = "0".repeat(desiredLength - itemString.length);
    return zeroToPad + itemString;
  }
}
