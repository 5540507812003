import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import DuesStatus from "./DuesStatus";
import DuesPayButton from "./DuesPayButton";

function DuesList({ data }) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-6 mt-10">
      {data &&
        data.map((item, index) => (
          <div key={index} className="card-wrapper">
            <Card>
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-4">
                  {item.name}
                </Typography>
                <Typography variant="paragraph" className="font-semibold">
                  National Dues: GH¢{item?.amount}
                </Typography>
                <div className="flex">
                  <Typography className="font-semibold" variant="paragraph">
                    Status:
                  </Typography>
                  <DuesStatus data={item} />
                </div>
              </CardBody>
              <CardFooter>
                <DuesPayButton data={item} />
              </CardFooter>
            </Card>
          </div>
        ))}
    </div>
  );
}

export default DuesList;
