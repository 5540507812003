import React, { useCallback, useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import {
  duesPayment,
  failedDuesPayment,
  verifyDuesPayment,
} from "../../../redux/actions/dues_action";
import { Button, Spinner } from "@material-tailwind/react";
import axios from "axios";

function DuesPayment({ post_type, amount, year, id, data, sub_type = null }) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [newManual, setNewManual] = useState({});
  const [ref, setRef] = useState("");

  const user = JSON.parse(localStorage.getItem("profile")).user;
  const { manual } = useSelector((state) => state.dues);

  const dispatch = useDispatch();

  const newAmount = amount * 100;
  const charge = newAmount * 0.02;
  const totalAmount = newAmount + charge;

  const handleGenerate = useCallback(() => {
    setRef(user.bar_number + "-" + new Date().getTime().toString());
  }, [setRef, user]);

  const config = {
    reference: ref,
    email: user?.email,
    amount: totalAmount,
    publicKey: "pk_live_68dddf36b26bb406911598f937c7a6b4071765e5",
    currency: "GHS",
    metadata: {
      section_id: id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const baseURL = "https://api.paystack.co";
    const axiosInstance = axios.create({ baseURL });
    const referenceId = reference.reference;

    const endpoint = `/transaction/verify/${referenceId}`;
    const method = "GET";

    axiosInstance({
      method,
      url: endpoint,
      headers: {
        Authorization:
          "Bearer sk_live_aee49e82f74c08ca7858ba553b21eaa41d20a888",
      },
    })
      .then((response) => {
        handleGenerate();

        const sendData = {
          post_type: post_type,
          year: year,
          sub_type: sub_type ? sub_type : null,
          id: id,
        };

        if (response.data.status) {
          dispatch(verifyDuesPayment(sendData)).finally(() => {
            setLiveLoading(false);
          });
        } else {
          const sendData = {
            reference: referenceId,
            year: data?.year,
          };
          dispatch(failedDuesPayment(sendData)).finally(() => {
            // handleGenerate();
            setLiveLoading(false);
            setNewManual({});
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const onClose = () => {
    setLiveLoading(false);
    setRef(user.bar_number + "-" + new Date().getTime().toString());
    window.location.reload();
  };

  const handleLive = (e) => {
    e.preventDefault();

    if (!ref) {
      setRef(user.bar_number + "-" + new Date().getTime().toString());
    }

    const formData = { post_type, amount, year, id, sub_type, reference: ref };

    setLiveLoading(true);
    dispatch(duesPayment(formData)).then(() => {
      if (newManual) {
        initializePayment(onSuccess, onClose);
        setLiveLoading(false);
      }
    });
  };

  useEffect(() => {
    handleGenerate();
  }, [handleGenerate, user.bar_number]);

  useEffect(() => {
    if (manual) {
      setNewManual(manual.receipt);
    }
  }, [manual]);

  return (
    <>
      <Button
        color={data && data.exempt ? "indigo" : "pink"}
        disabled={data && data.exempt}
        onClick={(e) => handleLive(e)}
      >
        {data && data.exempt ? (
          "Exempted"
        ) : liveLoading ? (
          <Spinner color="white" />
        ) : (
          "E-Payment"
        )}
      </Button>
    </>
  );
}

export default DuesPayment;
