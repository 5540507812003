import { IconButton, Tooltip } from "@material-tailwind/react";
import React from "react";

function AcceptIconButton({ onClick }) {
  return (
    <Tooltip content="Accept Button">
      <IconButton size="sm" className="color-green-50" onClick={onClick}>
        <svg
          height="18px"
          width="18px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M16.5163 8.93451L11.0597 14.7023L8.0959 11.8984"
              stroke="#009688"
              strokeWidth="2"
            ></path>
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#009688"
              strokeWidth="2"
            ></path>
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
}

export default AcceptIconButton;
