import {
  DELETE_GLC_CHAMBER,
  GET_GLC_CHAMBER,
  GET_GLC_PUPIL,
  UPDATE_GLC_CHAMBER,
  UPDATE_GLC_PUPIL,
  UPLOAD_GLC_REPORT_CHAMBER,
} from "../../constants/api_constants";
import { handleApi, handleFileUpload } from "../utils";

export const getGLCChamber = async () => {
  return handleApi("get", GET_GLC_CHAMBER);
};

export const updateGLCChamber = async (values) => {
  return handleApi("put", `${UPDATE_GLC_CHAMBER}/${values.id}`, values);
};

export const deleteGLCChamber = async (value) => {
  return handleApi("delete", `${DELETE_GLC_CHAMBER}/${value}`);
};

export const uploadGLCReportChamber = async (id, value) => {
  return handleFileUpload(`${UPLOAD_GLC_REPORT_CHAMBER}/${id}`, value);
};

export const getGLCPupil = async () => {
  return handleApi("get", GET_GLC_PUPIL);
};

export const updateGLCPupil = async (values) => {
  return handleApi("put", `${UPDATE_GLC_PUPIL}/${values.id}`, values);
};
