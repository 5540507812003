import React, { useState } from "react";
import SectionTitle from "../../common/shared/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../common/actions/AlertMessage";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
} from "@material-tailwind/react";
import InputText from "../../common/forms/InputText";
import { CHANGE_PASSWORD_DATA } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../redux/actions/auth_action";

const link = [{ name: "Profile Update", link: "" }];

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(CHANGE_PASSWORD_DATA);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updateError } = useSelector((state) => state.auth);

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setLoading(true);
    dispatch(changePassword(formData, navigate)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <SectionTitle title="Change Password" description="" children={link} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {updateError && <AlertMessage type="error" message={updateError} />}

        <Card>
          <form onSubmit={(e) => handleChangePassword(e)}>
            <CardBody>
              <InputText
                type="password"
                defaultValue={formData.password}
                updateType="password"
                labelTitle="Current Password"
                updateFormValue={updateFormValue}
                required={true}
              />

              <div className="grid grid-cols-1 sx:grid-cols-1 md:grid-cols-2 gap-4 my-4">
                <InputText
                  type="password"
                  defaultValue={formData.new_password}
                  updateType="new_password"
                  labelTitle="New Password"
                  updateFormValue={updateFormValue}
                  required={true}
                />

                <InputText
                  type="password"
                  defaultValue={formData.confirm_password}
                  updateType="confirm_password"
                  labelTitle="Confirm Password"
                  updateFormValue={updateFormValue}
                  required={true}
                />
              </div>
            </CardBody>
            <CardFooter className="flex justify-center">
              <Button type="submit" className="flex items-center gap-3">
                {loading && <Spinner color="white" />}
                Change Password
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
}

export default ChangePassword;
