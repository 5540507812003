import * as api from "../api/auth/auth_api";
import * as types from "../constants/auth_constants";
import { isValidToken } from "../../utils/authUtils";
import createAsyncAction from "../../utils/asyncAction";

export const setAccessToken = (accessToken) => async (dispatch) => {
  dispatch({ type: types.SET_ACCESS_TOKEN, payload: accessToken });
};

export const setUserData = (userData) => async (dispatch) => {
  dispatch({ type: types.SET_USER_DATA, payload: userData });
};

export const setInitialAuthState = (navigate) => async (dispatch) => {
  await dispatch({ type: types.LOGOUT });
  navigate("/");
};

export const initializeAuth = () => async (dispatch) => {
  const accessToken = JSON.parse(localStorage.getItem("profile"))?.token;

  if (accessToken) {
    if (isValidToken(accessToken)) {
      dispatch(setAccessToken(accessToken));
      dispatch(setUserData(JSON.parse(localStorage.getItem("profile")).user));
    }
  }
};

export const signUpAction = (formData, navigate) => async (dispatch) => {
  try {
    localStorage.removeItem("profile");
    const response = await api.signUp(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.SIGNUP_FAIL, payload: error });
    } else {
      const { user, token } = data;
      const profile = { user, token };
      localStorage.setItem("profile", JSON.stringify(profile));

      dispatch({ type: types.SIGNUP_SUCCESS, payload: profile });
      navigate("/home/update");
    }
  } catch (error) {
    dispatch({ type: types.SIGNUP_FAIL, payload: types.ERROR_MESSAGE });
  }
};

export const signInAction = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.signIn(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.SIGNIN_FAIL, payload: error });
    } else {
      const { user, token } = data;
      const profile = { user, token };
      localStorage.setItem("profile", JSON.stringify(profile));

      dispatch({ type: types.SIGNIN_SUCCESS, payload: profile });
      navigate("/home");
    }
  } catch (error) {
    dispatch({ type: types.SIGNIN_FAIL, payload: types.ERROR_MESSAGE });
  }
};

export const signUpCheck = (value, setIsModalOpen) => async (dispatch) => {
  try {
    const response = await api.signUpCheck(value);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.SIGNUP_CHECK_FAIL, payload: error });
    } else {
      const { user } = data;
      const check = { user };
      localStorage.setItem("check", JSON.stringify(check));

      dispatch({ type: types.SIGNUP_CHECK_SUCCESS, payload: check });
      setIsModalOpen(true);
    }
  } catch (error) {
    dispatch({ type: types.SIGNUP_CHECK_FAIL, payload: types.ERROR_MESSAGE });
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch({ type: types.LOGOUT, payload: null });
  } catch (error) {
    console.log(error);
  }
};

export const updateProfileAction = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.updateProfile(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.SIGNIN_FAIL, payload: error });
    } else {
      const { user, token, message } = data;
      const profile = { user, token };
      const allData = { user, token, message };

      navigate("/home");

      localStorage.setItem("profile", JSON.stringify(profile));
      dispatch({ type: types.UPDATE_SUCCESS, payload: allData });
    }
  } catch (error) {
    dispatch({ type: types.UPDATE_FAILURE, payload: types.ERROR_MESSAGE });
  }
};

export const changePassword = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.changePassword(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.CHANGE_PASSWORD_FAILURE, payload: error });
    } else {
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, payload: data });
      navigate("/home");
    }
  } catch (error) {
    dispatch({
      type: types.CHANGE_PASSWORD_FAILURE,
      payload: types.ERROR_MESSAGE,
    });
  }
};

export const forgortPassword = (formData) => async (dispatch) => {
  try {
    const response = await api.forgortPassword(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.FORGOT_PASSWORD_FAILURE, payload: error });
    } else {
      dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: types.FORGOT_PASSWORD_FAILURE,
      payload: types.ERROR_MESSAGE,
    });
  }
};

export const resetPassword = (formData, navigate) => async (dispatch) => {
  try {
    const response = await api.resetPassword(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.RESET_PASSWORD_FAILURE, payload: error });
    } else {
      dispatch({ type: types.RESET_PASSWORD_SUCCESS, payload: data });
      navigate("/");
    }
  } catch (error) {
    dispatch({
      type: types.RESET_PASSWORD_FAILURE,
      payload: types.ERROR_MESSAGE,
    });
  }
};

const getProfileTypes = {
  success: types.GET_PROFILE_SUCCESS,
  failure: types.GET_PROFILE_FAILURE,
};

export const getProfile = createAsyncAction(api.getProfile, getProfileTypes);
