import { Button, Spinner } from "@material-tailwind/react";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

function VerificationButton({
  reference,
  serial,
  apiFxn,
  id,
  apiFail,
  productId,
}) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const baseURL = "https://api.paystack.co";
  const axiosInstance = axios.create({ baseURL });

  const endpoint = `/transaction/verify/${reference}`;
  const method = "GET";

  const serial_key = serial;

  const displayErrorToast = (toast) => {
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="ml-3 flex-1">
              <p className="text-base font-medium text-red-950">Error!!</p>
              <p className="mt-1 text-base text-gray-500">
                Payment Unsuccessful. Please try again!
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
    ));
  };

  const handleVerification = () => {
    setLoading(true);
    axiosInstance({
      method,
      url: endpoint,
      headers: { Authorization: `Bearer ${serial_key}` },
    })
      .then((response) => {
        if (response.data.status === "success") {
          const formData = {
            id,
          };

          dispatch(apiFxn(formData))
            .then(() => {
              window.location.reload();
            })
            .finally(() => setLoading(false));
        } else {
          const formData = { id: id };

          dispatch(apiFail(formData))
            .then(() => {
              toast.custom((t) => {
                displayErrorToast(toast);
              });
              window.location.reload();
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        window.location.reload();
        displayErrorToast(toast);
        const formData = { id: id };

        dispatch(apiFail(formData))
          .then(() => {
            displayErrorToast(toast);
          })
          .finally(() => {
            setLoading(false);
            displayErrorToast(toast);
            console.log("hello");
          });
      });
  };

  return (
    <Button
      onClick={() => handleVerification()}
      color="deep-orange"
      className="text-center flex items-center justify-center"
    >
      {loading ? <Spinner color="white" /> : "Verify Payment"}
    </Button>
  );
}

export default VerificationButton;
