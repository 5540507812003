import React, { useEffect, useState } from "react";
import { CHANGE_PASSWORD_DATA } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../redux/actions/auth_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
} from "@material-tailwind/react";
import InputText from "../../common/forms/InputText";

function ResetPassword() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(CHANGE_PASSWORD_DATA);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetError = useSelector((state) => state.auth.resetError);

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage("");
    setFormData({ ...formData, [updateType]: value });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (formData.new_password !== formData.confirm_password) {
      setErrorMessage("Passwords do not match. Please try again");
      return;
    }

    setLoading(true);

    const sendData = {
      id: id,
      new_password: formData.new_password,
    };

    dispatch(resetPassword(sendData, navigate)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (resetError) {
      setErrorMessage(resetError);
    }
  }, [resetError]);

  return (
    <>
      <SectionTitle title="Reset Password" description="" children={[]} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {errorMessage && <AlertMessage type="error" message={errorMessage} />}

        <Card>
          <form onSubmit={(e) => handleChangePassword(e)}>
            <CardBody>
              <div className="grid grid-cols-1 sx:grid-cols-1 md:grid-cols-2 gap-4 my-4">
                <InputText
                  type="password"
                  defaultValue={formData.new_password}
                  updateType="new_password"
                  labelTitle="New Password"
                  updateFormValue={updateFormValue}
                  required={true}
                />

                <InputText
                  type="password"
                  defaultValue={formData.confirm_password}
                  updateType="confirm_password"
                  labelTitle="Confirm Password"
                  updateFormValue={updateFormValue}
                  required={true}
                />
              </div>
            </CardBody>
            <CardFooter className="flex justify-center">
              <Button type="submit" className="flex items-center gap-3">
                {loading && <Spinner color="white" />}
                Change Password
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
}

export default ResetPassword;
