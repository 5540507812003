import * as types from "../constants/dues_constants";

const initialState = {
  data: null,
  duesError: null,
};

const duesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DUES_SUCCESS:
    case types.NORMAL_DUES_SUCCESS:
    case types.VERIFY_DUES_SUCCESS:
    case types.DUES_PAYMENT_UPDATE_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.DUES_PAYMENT_SUCCESS:
      return { ...state, manual: payload ? payload : null };

    case types.GET_DUES_FAILURE:
    case types.DUES_PAYMENT_FAILURE:
    case types.NORMAL_DUES_FAILURE:
    case types.VERIFY_DUES_FAILURE:
    case types.DUES_PAYMENT_UPDATE_FAILURE:
      return { ...state, duesError: payload ? payload : null };

    default:
      return state;
  }
};

export default duesReducer;
