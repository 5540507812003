const totalCost = (data) => {
  let totalAmount = 0;
  let totalCharges = 0;

  data.forEach((receipt) => {
    totalAmount += receipt.amount;
    totalCharges += receipt.charges;
  });

  const combinedSum = totalAmount + totalCharges;
  return combinedSum;
};

export default totalCost;
