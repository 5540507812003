import {
  CONFERENCE_PAYMENT,
  GET_CONFERENCE,
  REGISTER_CONFERENCE,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const getConference = async () => {
  return handleApi("get", GET_CONFERENCE);
};

export const registerConference = async (value) => {
  return handleApi("post", REGISTER_CONFERENCE, value);
};

export const updateConference = async (value) => {
  return handleApi("put", `${CONFERENCE_PAYMENT}/${value.id}`, value);
};
