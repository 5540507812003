import * as api from "../api/license/license_api";
import * as types from "../constants/license_constants";

import createAsyncAction from "../../utils/asyncAction";

const userLicenseAction = {
  success: types.GET_LICENSE_SUCCESS,
  failure: types.GET_LICENSE_FAILURE,
};

const paymentLicenseAction = {
  success: types.LICENSE_PAYMENT_SUCCESS,
  failure: types.LICENSE_PAYMENT_FAILURE,
};

const manualLicenseTypes = {
  success: types.MANUAL_LICENSE_SUCCESS,
  failure: types.MANUAL_LICENSE_FAILURE,
};

const verifyLicensePaymentTypes = {
  success: types.VERIFY_LICENSE_SUCCESS,
  failure: types.VERIFY_LICENSE_FAILURE,
};

const failedLicensePaymentTypes = {
  success: types.FAILED_LICENSE_SUCCESS,
  failure: types.FAILED_LICENSE_FAILURE,
};

const orderLicenseRefTypes = {
  success: types.ORDER_LICENSE_REF_SUCCESS,
  failure: types.ORDER_LICENSE_REF_FAILURE,
};

const secondLicenseVerifyTypes = {
  success: types.SECOND_LICENSE_VERIFY_SUCCESS,
  failure: types.SECOND_LICENSE_VERIFY_FAILURE,
};

const secondLicenseFailTypes = {
  success: types.SECOND_LICENSE_FAIL_SUCCESS,
  failure: types.SECOND_LICENSE_FAIL_FAILURE,
};

export const getUserLicense = createAsyncAction(
  api.getUserLicense,
  userLicenseAction
);

export const licensePayment = createAsyncAction(
  api.licensePayment,
  paymentLicenseAction
);

export const licenseManual = createAsyncAction(
  api.licenseManual,
  manualLicenseTypes
);

export const verifyLicensePayment = createAsyncAction(
  api.verifyLicense,
  verifyLicensePaymentTypes
);

export const failedLicensePayment = createAsyncAction(
  api.failedLicense,
  failedLicensePaymentTypes
);

export const orderLicenseRef = createAsyncAction(
  api.orderLicenseRef,
  orderLicenseRefTypes
);

export const secondLicenseVerify = createAsyncAction(
  api.secondLicenseVerify,
  secondLicenseVerifyTypes
);

export const secondLicenseFail = createAsyncAction(
  api.secondLicenseFail,
  secondLicenseFailTypes
);

export const getLicenseCert = (formData) => async (dispatch) => {
  try {
    await api.licenseCert(formData);
  } catch (error) {
    dispatch({ type: types.GET_LICENSE_FAILURE, payload: error });
  }
};
