import ChamberIcon from "../../../assets/vectors/chamber_icon.svg";
import GLCPupilageIcon from "../../../assets/vectors/pupilage_icon.svg";

const GLCSection = [
  {
    title: "GLC Section",
    color: "green",
    menu: [
      {
        path: "/home/glc/chamber",
        name: "Chamber Request",
        description: "View all chamber application.",
        btn: "View",
        icon: ChamberIcon,
      },
      {
        path: "/home/glc/pupilage",
        name: "Pupillage Request",
        description: "View all Pupillage application.",
        btn: "View",
        icon: GLCPupilageIcon,
      },
    ],
  },
];

export default GLCSection;
