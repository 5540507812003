import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singlePupilage } from "../../redux/actions/pupilage_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import PupilageList from "./components/PupilageList";
import PupilageForm from "./components/PupilageForm";

const link = [{ name: "Pupillage Status", link: "" }];

function Pupilage() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, pupilageError } = useSelector((state) => state.pupilage);

  useEffect(() => {
    dispatch(singlePupilage()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle
        title="Pupillage"
        description=""
        children={link}
        link={"/home"}
      />

      {pupilageError && <AlertMessage type="error" message={pupilageError} />}

      {loading ? (
        <Loader />
      ) : data && data.pupil ? (
        <PupilageList data={data.pupil} />
      ) : (
        <PupilageForm />
      )}
    </div>
  );
}

export default Pupilage;
