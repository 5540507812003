import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import FallbackLoading from "./common/loader/FallbackLoading";
import { publicRoutes } from "./routes/public.routes";
import { privateRoutes } from "./routes/private.routes";
import ProctectedRoutes from "./routes/proctected.routes";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

function App() {
  const userData = useSelector((state) => state.auth?.userData);
  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route element={<ProctectedRoutes userData={userData} />}>
            {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>

          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{ style: { background: "#363636", color: "#fff" } }}
      />
    </>
  );
}

export default App;
