export const GET_LICENSE_SUCCESS = "GET_LICENSE_SUCCESS";
export const GET_LICENSE_FAILURE = "GET_LICENSE_FAILURE";

export const LICENSE_PAYMENT_SUCCESS = "LICENSE_PAYMENT_SUCCESS";
export const LICENSE_PAYMENT_FAILURE = "LICENSE_PAYMENT_FAILURE";

export const MANUAL_LICENSE_SUCCESS = "MANUAL_LICENSE_SUCCESS";
export const MANUAL_LICENSE_FAILURE = "MANUAL_LICENSE_FAILURE";

export const VERIFY_LICENSE_SUCCESS = "VERIFY_LICENSE_SUCCESS";
export const VERIFY_LICENSE_FAILURE = "VERIFY_LICENSE_FAILURE";

export const FAILED_LICENSE_SUCCESS = "FAILED_LICENSE_SUCCESS";
export const FAILED_LICENSE_FAILURE = "FAILED_LICENSE_FAILURE";

export const ORDER_LICENSE_REF_SUCCESS = "ORDER_LICENSE_REF_SUCCESS";
export const ORDER_LICENSE_REF_FAILURE = "ORDER_LICENSE_REF_FAILURE";

export const SECOND_LICENSE_VERIFY_SUCCESS = "SECOND_LICENSE_VERIFY_SUCCESS";
export const SECOND_LICENSE_VERIFY_FAILURE = "SECOND_LICENSE_VERIFY_FAILURE";

export const SECOND_LICENSE_FAIL_SUCCESS = "SECOND_LICENSE_FAIL_SUCCESS";
export const SECOND_LICENSE_FAIL_FAILURE = "SECOND_LICENSE_FAIL_FAILURE";
