import { handleApi } from "../utils";
import {
  ACTION_PUPILAGE,
  ADD_PUPILAGE,
  CHECK_PUPILAGE,
  PAYMENT_PUPILAGE,
  PUPILAGE_CERT,
  REQUEST_PUPILAGE,
  SINGLE_PUPILAGE,
  UPDATE_PUPILAGE,
  VERIFY_PUPILAGE_PAYMENT,
  FAILED_PUPILAGE_PAYMENT,
} from "../../constants/api_constants";

export const singlePupilage = async () => {
  return handleApi("get", SINGLE_PUPILAGE);
};

export const addPupilage = async (values) => {
  return handleApi("post", ADD_PUPILAGE, values);
};

export const checkPupilage = async (values) => {
  return handleApi("post", CHECK_PUPILAGE, values);
};

export const updatePupilage = async (values) => {
  return handleApi("put", `${UPDATE_PUPILAGE}/${values.id}`, values);
};

export const paymentPupilage = async (value) => {
  return handleApi("put", `${PAYMENT_PUPILAGE}/${value}`);
};

export const verifyPupilagePayment = async (values) => {
  return handleApi("put", VERIFY_PUPILAGE_PAYMENT, values);
};

export const failedPupilagePayment = async (values) => {
  return handleApi("put", FAILED_PUPILAGE_PAYMENT, values);
};

export const requestPipulage = async () => {
  return handleApi("get", REQUEST_PUPILAGE);
};

export const actionPupilage = async (value) => {
  return handleApi("get", `${ACTION_PUPILAGE}/${value}`);
};

export const pupilageCert = async (values) => {
  return handleApi("post", PUPILAGE_CERT, values);
};
