import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import OpenModal from "../../../common/actions/OpenModal";

import DuesPaymentPage from "./DuesPaymentPage";

function DuesPayButton({ data }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  // const handleNavigate = () => {
  //   navigate("/home/dues/payment", { state: { data } });
  // };

  // consterData = useSelector((state) => state.auth.userData);

  return (
    <>
      <Button onClick={() => handleOpen()} color="cyan" fullWidth>
        Make Payment / View Receipts
      </Button>

      {open && (
        <OpenModal
          open={open}
          handler={handleOpen}
          title="Dues Payments"
          size="xxl"
          children={<DuesPaymentPage data={data} />}
        />
      )}
    </>
  );
}

export default DuesPayButton;
