import * as types from "../constants/conference_constants";

const initialState = {
  data: null,
  conferenceError: null,
  register: null,
};

const conferenceReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CONFERENCE_SUCCESS:
    case types.CONFERENCE_PAYMENT_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.REGISTER_CONFERENCE_SUCCESS:
      return {
        ...state,
        data: payload ? payload : null,
        register: payload ? payload.register : null,
      };

    case types.REGISTER_CONFERENCE_FAILURE:
    case types.GET_CONFERENCE_FAILURE:
    case types.CONFERENCE_PAYMENT_FAILURE:
      return { ...state, conferenceError: payload ? payload : null };

    // case types.REGISTER_CONFERENCE_SUCCESS:
    //   return {
    //     ...state,
    //     reg_data: payload ? payload : null,
    //     reg_success: true,
    //   };

    default:
      return state;
  }
};

export default conferenceReducer;
