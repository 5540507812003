import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  uploadEvidence,
  uploadRGDFile,
  uploadSSNITFile,
  uploadVATFile,
} from "../../../redux/actions/chamber_actions";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import FileUpload from "../../../common/forms/FileUpload";

function ChamberFiles() {
  const { id } = useParams();

  const [rgdLoading, setRGDLoading] = useState(false);
  const [vatLoading, setVatLoading] = useState(false);
  const [ssnitLoading, setSSNITLoading] = useState(false);
  const [evidenceLoading, setEvidenceLoading] = useState(false);

  const dispatch = useDispatch();

  const { rgd, vat, ssnit, evidence } = useSelector((state) => state.chamber);

  const handleFileUpload = async (file, fileType) => {
    let setLoadingFunction;
    switch (fileType) {
      case "rgd":
        setLoadingFunction = setRGDLoading;
        await dispatch(uploadRGDFile(id, file));
        break;

      case "vat":
        setLoadingFunction = setVatLoading;
        await dispatch(uploadVATFile(id, file));
        break;

      case "ssnit":
        setLoadingFunction = setSSNITLoading;
        await dispatch(uploadSSNITFile(id, file));
        break;

      case "evidence":
        setLoadingFunction = setEvidenceLoading;
        await dispatch(uploadEvidence(id, file));
        break;

      default:
        break;
    }

    if (setLoadingFunction) {
      setLoadingFunction(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <Typography color="blue-gray" variant="h5" className="mb-10">
          Upload Required Files
        </Typography>
        <div className="grid grid-cols-2 gap-10 my-10">
          <FileUpload
            label="RGD Certificates"
            onUpload={(file) => handleFileUpload(file, "rgd")}
            helperText="(Form 3A-Unlimited Company, Form B - Act 152, Form A- Business Name)"
            loading={rgdLoading}
            checked={rgd}
          />

          <FileUpload
            label="VAT Registration Certificate"
            onUpload={(file) => handleFileUpload(file, "vat")}
            loading={vatLoading}
            checked={vat}
          />
        </div>

        <div className="grid grid-cols-2 gap-10 my-10">
          <FileUpload
            label="SSNIT Registration Certificate"
            onUpload={(file) => handleFileUpload(file, "ssnit")}
            loading={ssnitLoading}
            checked={ssnit}
          />

          <FileUpload
            label="Evidence of Client's Account "
            onUpload={(file) => handleFileUpload(file, "evidence")}
            helperText="(Copy of blank cheque/ Letter of confirmation from Bankers)"
            loading={evidenceLoading}
            checked={evidence}
          />
        </div>

        <div className="mt-20">
          <Typography variant="small" className="text-center">
            By clicking to proceed below you affirm that the information and
            data you have provided on this form are true; and that you are not
            knowingly submitting information which is untrue, misleading or
            false.
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}

export default ChamberFiles;
