import React, { useEffect, useState } from "react";
import DatePicker from "tailwind-datepicker-react";

function DatePickerComp({
  minDate = "1950-01-01",
  maxDate = "2050-01-01",
  label,
  onSelectDate,
  defaultDate,
}) {
  const [show, setShow] = useState(false);
  const [internalSelectedDate, setInternalSelectedDate] = useState(
    defaultDate ? new Date(defaultDate) : new Date()
  );

  const options = {
    maxDate: new Date(maxDate),
    minDate: new Date(minDate),
    datepickerClassNames: "top-12",
  };

  useEffect(() => {
    if (defaultDate) {
      setInternalSelectedDate(new Date(defaultDate));
    }
  }, [defaultDate]);

  const handleChange = (selectedDate) => {
    setInternalSelectedDate(selectedDate);
    onSelectDate(selectedDate);
  };

  const handleClose = (state) => {
    setShow(state);
  };

  return (
    <div>
      <span>{label}</span>
      <DatePicker
        options={options}
        onChange={handleChange}
        show={show}
        setShow={handleClose}
        value={internalSelectedDate}
      />
    </div>
  );
}

export default DatePickerComp;
