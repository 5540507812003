import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import ConferenceStatusTag from "../components/ConferenceStatusTag";
import ConferenceRegister from "./ConferenceRegister";
import ConferencePayment from "./ConferencePayment";
import ReceiptButton from "../../../common/buttons/ReceiptButton";

function ConferenceList({ row }) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-6 mt-10">
      {row &&
        row.map((data) => (
          <div key={data.id} className="card-wrapper">
            <Card>
              <CardBody>
                <Typography variant="h5" color="blue-gray" className="mb-4">
                  {data.name}
                </Typography>
                <Typography variant="paragraph" className="font-semibold">
                  Amount: GH¢{data.amount.toFixed(2)}
                </Typography>
                <div className="flex">
                  <Typography className="font-semibold" variant="paragraph">
                    Status:
                  </Typography>
                  <ConferenceStatusTag data={data} />
                </div>
              </CardBody>
              <CardFooter>
                {data.receiptStatus.status === null ? (
                  <ConferenceRegister item={data} />
                ) : data.exempt === true ? (
                  <ReceiptButton disabled={true} />
                ) : data.receiptStatus.status === 0 && data.amount > 0 ? (
                  <ConferencePayment data={data} />
                ) : (
                  <ReceiptButton id={data.receiptStatus.id} />
                )}
              </CardFooter>
            </Card>
          </div>
        ))}
    </div>
  );
}

export default ConferenceList;
