import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionPupilage,
  updatePupilage,
} from "../../../redux/actions/pupilage_action";
import SectionTitle from "../../../common/shared/SectionTitle";
import AlertMessage from "../../../common/actions/AlertMessage";
import Loader from "../../../common/loader/Loader";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";

const link = [
  { name: "Pupillage Request", link: "/home/pupilage/request" },
  { name: "Pupillage Action", link: "" },
];

function PupilageAction() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [acceptLoading, setAcceptLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, pupilageError } = useSelector((state) => state.pupilage);
  const user = useSelector((state) => state.auth?.userData);

  const today = new Date();

  const handleAccept = async (e) => {
    e.preventDefault();
    setAcceptLoading(true);
    const formData = {
      id: data.pupil.id,
      p_status: data.pupil.p_status + 1,
    };

    await dispatch(updatePupilage(formData)).finally(() => {
      setAcceptLoading(false);
      navigate("/home/pupilage/request");
    });
  };

  useEffect(() => {
    dispatch(actionPupilage(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Pupillage Action" description="" children={link} />

      {pupilageError && <AlertMessage type="error" message={pupilageError} />}

      {loading ? (
        <Loader />
      ) : (
        <Card>
          <CardBody>
            <div className="text-center mb-8">
              <Typography variant="h6" color="black">
                NOTIFICATION TO COUNCIL OF PROPOSED PUPILLAGE
              </Typography>

              <Typography variant="h6" color="black">
                LEGAL PROFESSION (PROFESSIONAL CONDUCT AND ETIQUETTE) RULES,
                2020 L.I. 2423, R4(15)
              </Typography>
            </div>
            <Typography color="black" variant="paragraph">
              I{" "}
              <strong className="uppercase underline decoration-solid">
                {user.title + " " + user.first_name + " " + user.last_name}
              </strong>
              {"   "}
              of{" "}
              <strong className="uppercase underline decoration-solid">
                {user.chamber_name}
              </strong>{" "}
              having been a lawyer since{"   "}
              <strong className="underline decoration-solid">
                {moment(user.date_of_call).format("YYYY")}
              </strong>{" "}
              hereby notify the Council under R4 (15) of L.I. 2423 of the
              proposed pupillage of{"   "}{" "}
              <strong className="uppercase underline decoration-solid">
                {data?.pupil?.u_name?.title +
                  " " +
                  data?.pupil?.u_name?.first_name +
                  " " +
                  data?.pupil?.u_name?.last_name}
              </strong>{" "}
              under me as a member of the above named Chambers / Legal
              Department.
            </Typography>
            <Typography className="my-6" variant="h6">
              DATED THIS {moment(today).format("Do")} DAY OF{" "}
              {moment(today).format("MMMM YYYY")}
            </Typography>
            <Typography variant="small" className="mb-10">
              The General Legal Council
              <br />
              GLC Committee on Chambers Registration
              <br />
              GBA National Secretariat
              <br />
              Accra
            </Typography>

            <hr />
            <div className="text-center">
              <Typography variant="small" color="black" className="mt-6">
                By clicking to proceed below you affirm that the information and
                data you have provided on this form are true; and that you are
                not knowingly submitting information which is untrue, misleading
                or false.
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="flex justify-center">
            <Button
              color="pink"
              className="flex items-center gap-3"
              onClick={(e) => handleAccept(e)}
            >
              {acceptLoading && <Spinner color="white" />}
              Accept Pupillage
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
}

export default PupilageAction;
