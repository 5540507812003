import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { setInitialAuthState } from "../redux/actions/auth_action";
import TopNav from "../common/shared/TopNav";

function ProctectedRoutes({ userData }) {
  const isAuthenticated = useMemo(() => {
    return (userData, accessToken) => {
      return userData && accessToken;
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("profile");
  const accessToken = JSON.parse(token)?.token;

  useEffect(() => {
    if (!isAuthenticated(userData, accessToken)) {
      dispatch(setInitialAuthState(navigate));
    }
  }, [dispatch, navigate, userData, accessToken, isAuthenticated]);

  return isAuthenticated(userData, accessToken) ? (
    <div className="bg-[#f5f5f5]">
      <TopNav />

      <main className="min-h-screen h-full pb-[5rem] ">
        <Outlet />
      </main>
    </div>
  ) : (
    <Navigate to="/" />
  );
}

export default ProctectedRoutes;
