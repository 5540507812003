import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserChamber,
  manualChamber,
  updateChamber,
} from "../../redux/actions/chamber_actions";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import checkYear from "../../utils/yearCheck";
import { useNavigate } from "react-router-dom";
import ChamberButtons from "./components/ChamberButtons";
import ChamberPayment from "./components/ChamberPayment";
import VerificationButton from "../../common/buttons/VerificationButton";
import {
  secondChamberFail,
  secondChamberVerify,
} from "../../redux/api/chamber/chamber_api";

const link = [{ name: "Chambers & Legal Department" }];

function Chambers() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [manualLoading, setManualLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, chamberError } = useSelector((state) => state.chamber);
  const user = useSelector((state) => state.auth?.userData);

  const handleNavigation = () => {
    navigate("/home/chamber/new");
  };

  const key = "sk_live_dae9b6a7960f00372df59df8fc55cfb034ef2ece";

  const handleSubmission = (id) => {
    setSubmitLoading(true);
    const formData = {
      id: id,
      stage: 1,
    };
    dispatch(updateChamber(formData, navigate)).finally(() =>
      setSubmitLoading(false)
    );
  };

  const handleManual = (e, id) => {
    e.preventDefault();
    const formData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
      chamberId: id,
    };

    setLoading(true);
    dispatch(manualChamber(formData)).finally(() => setLoading(false));
  };

  const today = new Date();

  useEffect(() => {
    dispatch(getUserChamber()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle
        title="Chambers & Legal Department"
        description=""
        children={link}
        link={"/home"}
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {chamberError && <AlertMessage type="error" message={chamberError} />}

        {loading ? (
          <Loader />
        ) : data.length === 0 ? (
          <>
            {checkYear(user.date_of_call) && (
              <Button color="pink" className="mb-4" onClick={handleNavigation}>
                Apply for New Chamber
              </Button>
            )}
            <NoContent message="No Data to display" />
          </>
        ) : (
          <>
            <Button color="pink" className="mb-4" onClick={handleNavigation}>
              Apply for New Chamber
            </Button>
            {data.map((item, index) => (
              <Card key={index} className="mb-6">
                <CardBody>
                  <Typography variant="h5" color="black">
                    {item.name}
                  </Typography>
                  <div className="flex ml-4 mb-8">
                    <ul className="flex space-x-8 list-disc">
                      <li className="py-1">
                        {item.headOf?.first_name +
                          " " +
                          item.headOf?.last_name +
                          " - " +
                          item.headOf?.bar_number}
                      </li>
                      {item.deputyOf && (
                        <li className="py-1">
                          {item.deputyOf?.first_name +
                            " " +
                            item.deputyOf?.last_name +
                            " - " +
                            item.deputyOf?.bar_number}
                        </li>
                      )}
                    </ul>
                  </div>
                  {(item.stage === 4 && item.exp_date) || item.exp_date ? (
                    <>
                      <div className="mb-4">
                        <Typography variant="paragraph">Expires On:</Typography>
                        <Typography
                          variant="lead"
                          color={item.alertExpire ? "red" : "black"}
                        >
                          <span className="font-bold">
                            {moment(item.exp_date).format("MMMM Do YYYY")}
                          </span>
                        </Typography>
                      </div>

                      <Typography variant="paragraph">
                        Chamber Number:
                      </Typography>
                      <Typography variant="lead">
                        <span className="font-bold">{item.chamber_num}</span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="paragraph">
                        Application Status:
                      </Typography>
                      <Typography variant="lead" color="black">
                        {item.chamber_status.name}
                      </Typography>
                    </>
                  )}

                  {item.rejected && (
                    <div className="mt-8">
                      <Typography variant="paragraph">
                        Rejected Reason
                      </Typography>
                      <Typography variant="lead" color="red">
                        {item.rejected_note}
                      </Typography>
                    </div>
                  )}
                </CardBody>

                <CardFooter className="flex item-center gap-3">
                  <>
                    {(user.bar_number === item.head ||
                      user.bar_number === item.deputy) && (
                      <Button
                        color="blue"
                        onClick={() =>
                          navigate(`/home/chamber/edit/${item.id}`)
                        }
                      >
                        Edit Details
                      </Button>
                    )}

                    {item.exp_date && <ChamberButtons item={item} />}

                    {item.status === 2 && item.url ? (
                      <VerificationButton
                        reference={item.url}
                        serial={key}
                        apiFxn={secondChamberVerify}
                        id={item.id}
                        apiFail={secondChamberFail}
                        productId={item.product.id}
                      />
                    ) : (
                      <>
                        {item.alertExpire &&
                          (item.head === user.bar_number ||
                            item.deputy === user.bar_number) && (
                            <>
                              <ChamberPayment data={item} />
                              <Button
                                color="amber"
                                onClick={(e) => handleManual(e, item.id)}
                                disabled={item.receipt.status === 0}
                              >
                                {manualLoading ? (
                                  <Spinner color="white" />
                                ) : (
                                  "Manual Pay"
                                )}
                              </Button>
                            </>
                          )}
                      </>
                    )}
                  </>

                  {item.stage === 0 && !item.exp_date && (
                    <Button
                      color="blue-gray"
                      type="button"
                      onClick={() => handleSubmission(item.id)}
                    >
                      {submitLoading ? (
                        <Spinner className="h-4 w-4" />
                      ) : (
                        "Submit Application"
                      )}
                    </Button>
                  )}

                  {item.stage === 4 && !item.exp_date && (
                    <ChamberPayment data={item} />
                  )}
                </CardFooter>
              </Card>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default Chambers;
