import React, { useState } from "react";
import SectionTitle from "../../../common/shared/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../common/actions/AlertMessage";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import FileUpload from "../../../common/forms/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateChamber,
  uploadEvidence,
  uploadRGDFile,
  uploadSSNITFile,
  uploadVATFile,
} from "../../../redux/actions/chamber_actions";

function FilesChamber() {
  const { id } = useParams();

  const [updateLoading, setUpdateLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chamberError, rgd, vat, ssnit, evidence } = useSelector(
    (state) => state.chamber
  );

  const handleSubmit = async () => {
    setUpdateLoading(true);
    const formData = {
      id: id,
      stage: 1,
    };
    dispatch(updateChamber(formData, navigate)).finally(() =>
      setUpdateLoading(false)
    );
  };

  const handleFileUpload = async (file, fileType) => {
    switch (fileType) {
      case "rgd":
        await dispatch(uploadRGDFile(id, file));
        break;

      case "vat":
        await dispatch(uploadVATFile(id, file));
        break;

      case "ssnit":
        await dispatch(uploadSSNITFile(id, file));
        break;

      case "evidence":
        await dispatch(uploadEvidence(id, file));
        break;

      default:
        break;
    }
  };

  return (
    <>
      <SectionTitle title="Chamber Files" description="" children={[]} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {chamberError && <AlertMessage type="error" message={chamberError} />}

        <Card>
          <CardBody>
            <Typography color="blue-gray" variant="h5" className="mb-10">
              Upload Required Files
            </Typography>
            <div className="grid grid-cols-2 gap-10 my-10">
              <FileUpload
                label="RGD Certificates"
                onUpload={(file) => handleFileUpload(file, "rgd")}
                helperText="(Form 3A-Unlimited Company, Form B - Act 152, Form A- Business Name)"
                checked={rgd}
              />

              <FileUpload
                label="VAT Registration Certificate"
                onUpload={(file) => handleFileUpload(file, "vat")}
                checked={vat}
              />
            </div>

            <div className="grid grid-cols-2 gap-10 my-10">
              <FileUpload
                label="SSNIT Registration Certificate"
                onUpload={(file) => handleFileUpload(file, "ssnit")}
                checked={ssnit}
              />

              <FileUpload
                label="Evidence of Client's Account "
                onUpload={(file) => handleFileUpload(file, "evidence")}
                helperText="(Copy of blank cheque/ Letter of confirmation from Bankers)"
                checked={evidence}
              />
            </div>

            <div className="mt-20">
              <Typography variant="small" className="text-center">
                By clicking to proceed below you affirm that the information and
                data you have provided on this form are true; and that you are
                not knowingly submitting information which is untrue, misleading
                or false.
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="flex justify-center">
            <Button
              type="button"
              className="flex item-center gap-3"
              onClick={handleSubmit}
            >
              {updateLoading ? (
                <Spinner className="h-4 w-4" />
              ) : (
                "Submit Application"
              )}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default FilesChamber;
