import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import NoContent from "../../../common/shared/NoContent";

function ChangeProfileImg() {
  return (
    <Card>
      <CardBody>
        <NoContent message="All Images are yet to be uploaded" />
      </CardBody>
    </Card>
  );
}

export default ChangeProfileImg;
