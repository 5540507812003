import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/dues/dues_api";
import * as types from "../constants/dues_constants";

const userDuesTypes = {
  success: types.GET_DUES_SUCCESS,
  failure: types.GET_DUES_FAILURE,
};

const normalDuesTypes = {
  success: types.NORMAL_DUES_SUCCESS,
  failure: types.NORMAL_DUES_FAILURE,
};

const updateDuesTypes = {
  success: types.NORMAL_DUES_SUCCESS,
  failure: types.NORMAL_DUES_FAILURE,
};

const verifyDuesTypes = {
  success: types.VERIFY_DUES_SUCCESS,
  failure: types.VERIFY_DUES_FAILURE,
};

const failedDuesTypes = {
  success: types.FAILED_DUES_SUCCESS,
  failure: types.FAILED_DUES_FAILURE,
};

const secondDuesVerifyTypes = {
  success: types.SECOND_DUES_VERIFY_SUCCESS,
  failure: types.SECOND_DUES_VERIFY_FAILURE,
};

const secondDuesFailTypes = {
  success: types.SECOND_DUES_FAIL_SUCCESS,
  failure: types.SECOND_DUES_FAIL_FAILURE,
};

export const userDues = createAsyncAction(api.getUserDues, userDuesTypes);
export const normalDues = createAsyncAction(api.normalDues, normalDuesTypes);
export const updateDues = createAsyncAction(api.udpateReceipt, updateDuesTypes);
export const verifyDuesPayment = createAsyncAction(
  api.verifyDuesPayment,
  verifyDuesTypes
);
export const failedDuesPayment = createAsyncAction(
  api.failedDuesPayment,
  failedDuesTypes
);
export const secondDuesVerify = createAsyncAction(
  api.secondDuesVerify,
  secondDuesVerifyTypes
);

export const secondDuesFail = createAsyncAction(
  api.secondDuesFail,
  secondDuesFailTypes
);

// export const duesPayment = createAsyncAction(api.duesPayment, duesPaymentTypes);

export const duesPayment = (formData) => async (dispatch) => {
  try {
    const response = await api.duesPayment(formData);
    const { error, data } = response;

    if (error) {
      dispatch({ type: types.DUES_PAYMENT_FAILURE, payload: error });
    } else {
      const userData = JSON.parse(localStorage.getItem("profile"));
      userData.user.standing = true;
      localStorage.setItem("profile", JSON.stringify(userData));

      dispatch({ type: types.DUES_PAYMENT_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: types.DUES_PAYMENT_FAILURE, payload: error });
  }
};
