import axios from "axios";
import React, { useEffect, useState } from "react";
import createAppStore from "../redux/store";
import Loader from "../common/loader/Loader";
import { Provider } from "react-redux";

import App from "../App";
import ScrollToTop from "../middlewares/ScrollToTop";

// const BASE_URL = "https://gba-1.azurewebsites.net";
// const BASE_URL = "https://gbatest.adroit360gh.com";
const BASE_URL = "https://gba2.azurewebsites.net";
// const BASE_URL = "http://localhost:3500";

const ErrorComponent = ({ errorMessage }) => (
  <div className="text-red-500 font-bold text-center">{errorMessage}</div>
);

function AppContainer() {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        await axios.create({ baseURL: `${BASE_URL}/server-status` });
      } catch (error) {
        setError("Server is down. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    checkServerStatus();
  }, []);

  useEffect(() => {
    const initializeStore = async () => {
      try {
        const appStore = await createAppStore();
        setStore(appStore);
      } catch (error) {
        setError(`Error initializing the app: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    initializeStore();
  }, []);

  if (loading || error) {
    return (
      <div className="flex items-center justify-center h-screen">
        {loading ? <Loader /> : <ErrorComponent errorMessage={error} />}
      </div>
    );
  }

  return (
    <Provider store={store}>
      <ScrollToTop />
      <App />
    </Provider>
  );
}

export default AppContainer;
