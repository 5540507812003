import React from "react";

const FolderComp = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={100}
      height={100}
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M185.04 48H32C14.328 48 0 62.328 0 80v104h512v-56c0-17.672-14.328-32-32-32H254.96a32.012 32.012 0 0 1-24.56-11.52l-20.8-24.96A32.012 32.012 0 0 0 185.04 48z"
        style={{
          fill: "#e78825",
        }}
      />
      <path
        d="M32 152h448c17.672 0 32 14.328 32 32v248c0 17.672-14.328 32-32 32H32c-17.672 0-32-14.328-32-32V184c0-17.672 14.328-32 32-32z"
        style={{
          fill: "#f6b545",
        }}
      />
      <path
        d="M496 200v232c0 8.84-7.16 16-16 16H48c-8.84 0-16 7.16-16 16h448c17.672 0 32-14.328 32-32V184c-8.84 0-16 7.16-16 16z"
        style={{
          fill: "#f19920",
        }}
      />
      <path
        d="M320 312H208c-8.84 0-16-7.16-16-16s7.16-16 16-16h112c8.84 0 16 7.16 16 16s-7.16 16-16 16z"
        style={{
          fill: "#e78825",
        }}
      />
      <path
        d="M472 144c0-8.84-7.16-16-16-16H64c-8.84 0-16 7.16-16 16v8h424v-8z"
        style={{
          fill: "#fff",
        }}
      />
    </svg>
  );
};

export default FolderComp;
