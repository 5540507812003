function formatDate(dateString) {
  if (typeof dateString !== "string") {
    throw new Error("Invalid date input");
  }

  const trimmedDate = dateString.split("T")[0];
  return trimmedDate;
}

export default formatDate;
