import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { createCanvas } from "canvas";
import { Months } from "../../../utils/months";
import SectionTitle from "../../../common/shared/SectionTitle";
import { Button } from "@material-tailwind/react";

function ChamberCertificate() {
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const location = useLocation();

  const user = useSelector((state) => state.auth?.userData);

  const certData = location.state?.formData;

  const formData = useMemo(
    () => ({
      year: certData?.year,
      cert_number: certData?.cert_number,
      name: certData.name,
      exp_date: certData?.exp_date,
    }),
    [certData]
  );

  const handleSavePDF = () => {
    if (pdfDataUrl) {
      const canvas = document.getElementById("certificateCanvas");

      html2canvas(canvas, {
        width: 800,
        height: 1000,
      }).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297);

        pdf.save(`${formData.year}_Solicitors_License.pdf`);
      });
    }
  };

  // const handleSavePDF = () => {
  //   if (pdfDataUrl) {
  //     const canvas = document.getElementById("certificateCanvas");
  //     const ctx = canvas.getContext("2d");

  //     const img = new Image();
  //     img.crossOrigin = "anonymous";
  //     img.src = pdfDataUrl;

  //     img.onload = () => {
  //       ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //       html2canvas(document.getElementById("certificateCanvas"), {
  //         width: 800,
  //         height: 1000,
  //       }).then((canvas) => {
  //         const pdf = new jsPDF("p", "mm", "a4");
  //         pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297);
  //         // pdf.save(`${formData.year}_Solicitors_License.pdf`);

  //         const pdfDataUri = pdf.output("datauri");
  //         const pdfWindow = window.open(pdfDataUri);

  //         if (!pdfWindow) {
  //           alert(
  //             "Please use your browser's print functionality to print the document."
  //           );

  //           const downloadLink = document.createElement("a");
  //           downloadLink.href = pdfDataUri;
  //           downloadLink.download = `${formData.year}_Solicitors_License.pdf`;
  //           document.body.appendChild(downloadLink);
  //           downloadLink.click();
  //           document.body.removeChild(downloadLink);
  //         }
  //       });
  //     };
  //   }
  // };

  useEffect(() => {
    const generateCertificate = async () => {
      const image = new Image();
      image.src = require(`../../../assets/chamber/chamber${formData.year}.jpg`);
      await image.decode();

      const canvas = createCanvas(image.width, image.height);
      const ctx = canvas.getContext("2d");

      ctx.drawImage(image, 0, 0);
      ctx.font = "bold 25px Arial";
      ctx.fillText(formData.cert_number, 680, 137);

      const nameText = formData.name;
      const textWidth = ctx.measureText(nameText).width;
      const xCoordinate = (canvas.width - textWidth) / 2;
      ctx.fillText(nameText.toUpperCase(), xCoordinate, 635);

      const date = new Date(formData.exp_date);
      const day = date.getDate();
      const month = Months[date.getMonth()];
      const yearDate = date.getFullYear();

      ctx.fillText(day, 250, 820);
      ctx.fillText(month, 500, 820);
      ctx.fillText(yearDate % 100, 750, 825);

      const dataUrl = canvas.toDataURL("image/png");

      setPdfDataUrl(dataUrl);

      const certificateImg = document.createElement("img");
      certificateImg.src = dataUrl;
      document
        .getElementById("certificateCanvasContainer")
        .appendChild(certificateImg);
    };

    generateCertificate();
  }, [formData]);

  return (
    <>
      <SectionTitle title="Chamber Certificate" description="" children={[]} />

      <div className="my-8 max-w-[1200px] m-auto px-4 flex flex-col items-center justify-center">
        <div className="flex items-center gap-3 mb-8">
          <div>
            <Button onClick={handleSavePDF} fullWidth>
              Save Cert
            </Button>
          </div>
        </div>

        <div id="certificateCanvasContainer" className="relative">
          <canvas id="certificateCanvas" />
        </div>
      </div>
    </>
  );
}

export default ChamberCertificate;
