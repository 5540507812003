const resourceItems = [
  {
    name: "2022 / 2023 GBA Annual Conference",
    link: "https://drive.google.com/drive/folders/1HbvjpsIx13yP7WzuBlPn8I3QP1_qjSGb?usp=sharing",
  },
  {
    name: "2022 Mid-Year Review Conference",
    link: "https://drive.google.com/drive/folders/11YFPP-JBrILsj47GiV5O-v5yjh0vgqCJ?usp=sharing",
  },
  {
    name: "2022 Bench, Bar and Faculty Conference",
    link: "https://drive.google.com/drive/folders/1Dgi0DgUgXeTeirI6RkhYGYL455lzZNRJ?usp=sharing",
  },
  {
    name: "2021 / 2022 GBA Annual Conference",
    link: "https://drive.google.com/drive/folders/1btDxmx_cG7YXkbhiRV9FE9zlsZqzXwjU?usp=sharing",
  },
  {
    name: "2021 — Continuous Legal Education (CLE)",
    link: "https://drive.google.com/drive/folders/1UP0cFXJ0UOiM_MWLUsXdHrlPkEixckg5?usp=sharing",
  },
];

export default resourceItems;
