import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { registerConference } from "../../../redux/actions/conference_action";
import { Button, Spinner } from "@material-tailwind/react";

function ConferenceRegister({ item }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleRegister = async () => {
    setLoading(true);

    await dispatch(registerConference(item)).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Button
      color="light-blue"
      className="flex items-center gap-3"
      onClick={handleRegister}
    >
      {loading ? <Spinner color="white" /> : "Register"}
    </Button>
  );
}

export default ConferenceRegister;
