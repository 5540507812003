export const GET_DUES_SUCCESS = "GET_DUES_SUCCESS";
export const GET_DUES_FAILURE = "GET_DUES_FAILURE";

export const DUES_PAYMENT_SUCCESS = "DUES_PAYMENT_SUCCESS";
export const DUES_PAYMENT_FAILURE = "DUES_PAYMENT_FAILURE";

export const NORMAL_DUES_SUCCESS = "NORMAL_DUES_SUCCESS";
export const NORMAL_DUES_FAILURE = "NORMAL_DUES_FAILURE";

export const DUES_PAYMENT_UPDATE_SUCCESS = "DUES_PAYMENT_UPDATE_SUCCESS";
export const DUES_PAYMENT_UPDATE_FAILURE = "DUES_PAYMENT_UPDATE_FAILURE";

export const VERIFY_DUES_SUCCESS = "VERIFY_DUES_SUCCESS";
export const VERIFY_DUES_FAILURE = "VERIFY_DUES_FAILURE";

export const FAILED_DUES_SUCCESS = "FAILED_DUES_SUCCESS";
export const FAILED_DUES_FAILURE = "FAILED_DUES_FAILURE";

export const SECOND_DUES_VERIFY_SUCCESS = "SECOND_DUES_VERIFY_SUCCESS";
export const SECOND_DUES_VERIFY_FAILURE = "SECOND_DUES_VERIFY_FAILURE";

export const SECOND_DUES_FAIL_SUCCESS = "SECOND_DUES_FAIL_SUCCESS";
export const SECOND_DUES_FAIL_FAILURE = "SECOND_DUES_FAIL_FAILURE";
