import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import GLCPupilageIcon from "../../../assets/GLCPupilageIcon";

function GLCPupilageCard({ user }) {
  const navigate = useNavigate();
  const isGLC = user.isGLC;

  return (
    <>
      {isGLC && (
        <Card
          color="gray"
          className="w-full h-full flex justify-center items-center"
        >
          <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
            <div className="mx-auto mb-6 w-[4rem] h-[4rem]">
              <GLCPupilageIcon />
            </div>
            <Typography variant="h5" color="white">
              Pupillage Request
            </Typography>
            <Typography>View all Pupillage application.</Typography>
            <Button
              onClick={() => navigate("/home/glc/pupilage")}
              className="mt-10"
              color="white"
              fullWidth
            >
              View
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default GLCPupilageCard;
