import * as types from "../constants/chamber_constants";

const initialState = {
  data: null,
  chamberError: null,
  rgd: null,
  vat: null,
  ssnit: null,
  evidence: null,
  message: null,
  members: null,
  manual: null,
};

const chamberReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_CHAMBER_SUCCESS:
    case types.APPLY_CHAMBER_SUCCESS:
    case types.SINGLE_USER_CHAMBER_SUCCESS:
    case types.MANUAL_CHAMBER_SUCCESS:
    case types.VERIFY_CHAMBER_SUCCESS:
    case types.SECOND_CHAMBER_VERIFY_SUCCESS:
    case types.SECOND_CHAMBER_FAIL_SUCCESS:
      return { ...state, data: payload ? payload.chamber : null };

    case types.CHAMBER_PAYMENT_SUCCESS:
      return { ...state, manual: payload ? payload.chamber : null };

    case types.GET_CHAMBER_MEMBER_SUCCESS:
    case types.ADD_CHAMBER_MEMBER_SUCCESS:
    case types.REMOVE_CHAMBER_MEMBER_SUCCESS:
      return { ...state, members: payload ? payload.chamber : null };
    case types.UPLOAD_CHAMBER_RGD_SUCCESS:
      return { ...state, rgd: payload ? payload.rgd : null };

    case types.UPLOAD_CHAMBER_VAT_SUCCESS:
      return { ...state, vat: payload ? payload.vat : null };

    case types.UPLOAD_CHAMBER_SSNIT_SUCCESS:
      return { ...state, ssnit: payload ? payload.ssnit : null };

    case types.UPLOAD_CHAMBER_EVIDENCE_SUCCESS:
      return { ...state, evidence: payload ? payload.evidence : null };

    case types.UPDATE_CHAMBER_SUCCESS:
      return { ...state, message: payload ? payload : null };

    case types.GET_USER_CHAMBER_FAILURE:
    case types.APPLY_CHAMBER_FAILURE:
    case types.GET_CHAMBER_MEMBER_FAILURE:
    case types.ADD_CHAMBER_MEMBER_FAILURE:
    case types.REMOVE_CHAMBER_MEMBER_FAILURE:
    case types.UPLOAD_CHAMBER_RGD_FAILURE:
    case types.UPLOAD_CHAMBER_VAT_FAILURE:
    case types.UPLOAD_CHAMBER_SSNIT_FAILURE:
    case types.UPLOAD_CHAMBER_EVIDENCE_FAILURE:
    case types.UPDATE_CHAMBER_FAILURE:
    case types.SINGLE_USER_CHAMBER_FAILURE:
    case types.VERIFY_CHAMBER_FAILURE:
    case types.CHAMBER_PAYMENT_FAILURE:
    case types.MANUAL_CHAMBER_FAILURE:
    case types.SECOND_CHAMBER_VERIFY_FAILURE:
    case types.SECOND_CHAMBER_FAIL_FAILURE:
      return { ...state, chamberError: payload ? payload : null };

    default:
      return state;
  }
};

export default chamberReducer;
