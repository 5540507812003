import React, { useCallback, useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import {
  failedLicensePayment,
  licenseManual,
  licensePayment,
  secondLicenseFail,
  secondLicenseVerify,
  verifyLicensePayment,
} from "../../../redux/actions/license_action";
import { Button, Spinner } from "@material-tailwind/react";
import axios from "axios";
import VerificationButton from "../../../common/buttons/VerificationButton";

function LicensePayment({ data }) {
  const [liveLoading, setLiveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newManual, setNewManual] = useState({});
  const [ref, setRef] = useState("");

  const user = JSON.parse(localStorage.getItem("profile")).user;
  const { manual } = useSelector((state) => state.license);

  const dispatch = useDispatch();

  const newAmount = data.amount * 100;
  const charge = newAmount * 0.02;
  const totalAmount = newAmount + charge;

  const handleGenerate = useCallback(() => {
    setRef(user.bar_number + "-" + new Date().getTime().toString());
  }, [setRef, user]);

  useEffect(() => {
    handleGenerate();
  }, [handleGenerate]);

  const config = {
    reference: ref,
    email: user?.email,
    amount: totalAmount,
    publicKey: "pk_live_f4fd8b047b43cd62eaaf8a98c8701e77cf58ab99",
    currency: "GHS",
    metadata: {
      section_id: data.id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const key = "sk_live_7f5e2f3497fd0f994ee599206265fe0b6a56fc86";

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    const baseURL = "https://api.paystack.co";
    const axiosInstance = axios.create({ baseURL });
    const referenceId = reference.reference;

    const endpoint = `/transaction/verify/${referenceId}`;
    const method = "GET";

    axiosInstance({
      method,
      url: endpoint,
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
      .then((response) => {
        const sendData = {
          post_type: "license",
          year: data.year,
          id: data?.id,
        };

        if (response.data.status) {
          dispatch(verifyLicensePayment(sendData)).finally(() =>
            setLiveLoading(false)
          );
        } else {
          const sendData = {
            reference: referenceId,
            year: data?.year,
          };
          dispatch(failedLicensePayment(sendData)).finally(() =>
            setLiveLoading(false)
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const onClose = () => {
    console.log(1);
    setLiveLoading(false);
    handleGenerate();
    console.log(2);
  };

  const handleManual = (e) => {
    e.preventDefault();
    const formData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
    };

    setLoading(true);
    dispatch(licenseManual(formData)).finally(() => setLoading(false));
  };

  const handleLive = (e) => {
    e.preventDefault();

    if (!ref) {
      handleGenerate();
    }

    const sendData = {
      amount: data.amount,
      year: data.year,
      id: data.id,
      reference: ref,
    };

    setLiveLoading(true);
    dispatch(licensePayment(sendData))
      .then(() => {
        handleGenerate();
        if (newManual) {
          console.log("New Maniual", newManual);
          initializePayment(onSuccess, onClose);
        } else {
          setLiveLoading(false);
        }
      })
      .catch((error) => {
        handleGenerate();
        console.error("License payment error:", error);
        setLiveLoading(false);
      });
  };

  useEffect(() => {
    if (manual) {
      setNewManual(manual.receipt);
    }
  }, [manual]);

  return data?.receipt?.status === 2 ? (
    <VerificationButton
      reference={data.receipt.url}
      serial={key}
      apiFxn={secondLicenseVerify}
      id={data.receipt.id}
      apiFail={secondLicenseFail}
      productId={data.receipt.product_id}
    />
  ) : (
    <div className="grid grid-cols-1 sx:grid-cols-1 md:grid-cols-2 gap-4 my-4">
      <Button
        disabled={!data.standing || !data.pupilStatus || !data.isRegion}
        color="pink"
        onClick={(e) => handleLive(e)}
      >
        {liveLoading ? <Spinner color="white" /> : "E-Payment"}
      </Button>

      <Button
        disabled={
          !data.standing ||
          !data.pupilStatus ||
          data?.receipt ||
          newManual?.receipts ||
          !data.isRegion
        }
        color="amber"
        onClick={(e) => handleManual(e)}
      >
        {loading ? <Spinner color="white" /> : "Manual Pay"}
      </Button>
    </div>
  );
}

export default LicensePayment;
