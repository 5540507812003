import {
  Button,
  Card,
  CardBody,
  Chip,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import moment from "moment";
import welcome from "../../../assets/img/welcome.jpg";
import { useNavigate } from "react-router-dom";

function UserBanner() {
  const user = JSON.parse(localStorage.getItem("profile")).user;

  const navigate = useNavigate();

  return (
    <>
      <div className="relative overflow-hidden">
        <img
          src={welcome}
          alt="welcome"
          className=" h-[350px] w-screen object-cover brightness-50"
        />
        {/* <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white"></h1>
        </div> */}
      </div>
      <div className="max-w-[1200px] mx-auto px-4">
        <div className="mt-[-100px]">
          <Card className="w-full">
            <CardBody className="flex flex-col lg:flex-row">
              <div className="lg:w-[220px] lg:mr-4 rounded-lg overflow-clip mb-4 lg:mb-0 w-full lg:w-auto">
                <img
                  src={user.avatar ? `/avatar/${user.avatar}` : "./default.jpg"}
                  height="160"
                  width="160"
                  alt=""
                />
              </div>
              <div className="w-full">
                <div className="flex justify-between">
                  <div>
                    <span>Name</span>
                    <Typography variant="h5" color="black">
                      {user.title +
                        " " +
                        user.first_name +
                        " " +
                        user.last_name}
                    </Typography>
                  </div>

                  <Button onClick={() => navigate("/home/update")}>
                    Edit Profile
                  </Button>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
                  <div className="w-full lg:w-1/2 lg:pr-2 mb-2 lg:mb-0">
                    <span>Bar Number</span>
                    <Typography variant="lead" color="black">
                      {user.bar_number}
                    </Typography>
                  </div>
                  <div className="w-full lg:w-1/2 lg:pl-2">
                    <span>Date Of Call</span>
                    <Typography variant="lead" color="black">
                      {moment(user.date_of_call).format("MMMM Do YYYY")}
                    </Typography>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
                  {user.chamber_name && (
                    <div className="w-full lg:w-1/2 lg:pr-2 mb-2 lg:mb-0">
                      <span>Chamber Name</span>
                      <Typography variant="lead" color="black">
                        {user.chamber_name}
                      </Typography>
                    </div>
                  )}

                  <div className="w-full lg:w-1/2">
                    <span>Status</span>
                    <div className="flex">
                      {user.standing.good_standing === true ? (
                        <Chip
                          variant="ghost"
                          color="green"
                          size="sm"
                          value="In Good Financial Standing"
                        />
                      ) : (
                        <Chip
                          variant="ghost"
                          color="red"
                          size="sm"
                          value="Not in Good Financial Standing"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* <div className="max-w-[1200px] m-auto px-4">
        <div className="mt-[-100px] ">
          <Card className="h-[250px] w-full">
            <CardBody className="flex">
              <div className=" h-[180px] w-[110px] lg:h-[180px] lg:w-[220px]  rounded-lg overflow-clip">
                <img src="./default.jpg" height="160" width="160" alt="" />
              </div>
              <div className="w-full">
                <div>
                  <span>Name</span>
                  <Typography variant="h5" color="black">
                    {user.title + " " + user.first_name + " " + user.last_name}
                  </Typography>
                </div>

                <div className="flex items-center justify-between mt-4">
                  <div className="w-1/2">
                    <span>Bar Number</span>
                    <Typography variant="lead" color="black">
                      {user.bar_number}
                    </Typography>
                  </div>
                  <div className="w-1/2">
                    <span>Date Of Call</span>
                    <Typography variant="lead" color="black">
                      {moment(user.date_of_call).format("MMMM Do YYYY")}
                    </Typography>
                  </div>
                  <div></div>
                </div>

                <div className="flex items-center justify-between mt-4">
                  {user.chamber_name && (
                    <div className="w-1/2">
                      <span>Chamber Name</span>
                      <Typography variant="lead" color="black">
                        {user.chamber_name}
                      </Typography>
                    </div>
                  )}

                  <div className="w-1/2">
                    <span>Status</span>
                    <div className="flex">
                      {user.status === true ? (
                        <Chip
                          variant="ghost"
                          color="green"
                          size="sm"
                          value="In Good Standing"
                        />
                      ) : (
                        <Chip
                          variant="ghost"
                          color="red"
                          size="sm"
                          value="Not in Good Standing"
                        />
                      )}
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div> */}
    </>
  );
}

export default UserBanner;
