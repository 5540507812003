import React, { useEffect } from "react";
import UserBanner from "./components/UserBanner";
import ServiceCard from "./components/ServiceCard";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Home() {
  const user = useSelector((state) => state.auth?.userData);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.p_region || !user.email || !user.phone || !user.chamber_name) {
      navigate("/home/update");
    }
  });

  return (
    <>
      {/* <SectionTitle title="Welcome" /> */}
      <UserBanner />
      <ServiceCard />

      <div className="mx-auto p-2 px-4 max-w-[1200px] pt-8 text-center">
        <Typography variant="small">
          On this portal, you can manage your profile, apply for and renew your
          license, etc. Click to update profile Kindly Contact the GBA on{" "}
          <strong>info@ghanabar.org</strong> or call{" "}
          <strong>+233 30 291 2977</strong> should you encounter any challenges
          using this portal. Thank you.
        </Typography>
      </div>
    </>
  );
}

export default Home;
