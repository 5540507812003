import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultipleOptions({ options, updateFormValue, select = [] }) {
  const [selectedOptions, setSelectedOptions] = useState(select);

  const handleAutocompleteChange = (event, values) => {
    const selectedIds = values.map((value) => value.id);
    setSelectedOptions(selectedIds);
    updateFormValue({ updateType: "practice_areas", value: selectedIds });
  };

  useEffect(() => {
    if (select.length > 0) {
      const selectIds = select.map((item) => item.id);
      setSelectedOptions(selectIds);
    }
  }, [select]);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(opt) => opt.name}
      value={options.filter((opt) => selectedOptions.includes(opt.id))}
      onChange={handleAutocompleteChange}
      renderOption={(props, opt, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {opt.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label="Practice Areas" />}
    />
  );
}

export default MultipleOptions;
