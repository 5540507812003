export const SINGLE_PUPILAGE_SUCCESS = "SINGLE_PUPILAGE_SUCCESS";
export const SINGLE_PUPILAGE_FAILURE = "SINGLE_PUPILAGE_FAILURE";

export const ADD_PUPILAGE_SUCCESS = "ADD_PUPILAGE_SUCCESS";
export const ADD_PUPILAGE_FAILURE = "ADD_PUPILAGE_FAILURE";

export const CHECK_PUPILAGE_SUCCESS = "CHECK_PUPILAGE_SUCCESS";
export const CHECK_PUPILAGE_FAILURE = "CHECK_PUPILAGE_FAILURE";

export const UPDATE_PUPILAGE_SUCCESS = "UPDATE_PUPILAGE_SUCCESS";
export const UPDATE_PUPILAGE_FAILURE = " UPDATE_PUPILAGE_FAILURE";

export const REQUEST_PUPILAGE_SUCCESS = "REQUEST_PUPILAGE_SUCCESS";
export const REQUEST_PUPILAGE_FAILURE = "REQUEST_PUPILAGE_FAILURE";

export const ACTION_PUPILAGE_SUCCESS = "ACTION_PUPILAGE_SUCCESS";
export const ACTION_PUPILAGE_FAILURE = "ACTION_PUPILAGE_FAILURE";

export const PAYMENT_PUPILAGE_SUCCESS = "PAYMENT_PUPILAGE_SUCCESS";
export const PAYMENT_PUPILAGE_FAILURE = "PAYMENT_PUPILAGE_FAILURE";

export const VERIFY_PUPILAGE_SUCCESS = "VERIFY_PUPILAGE_SUCCESS";
export const VERIFY_PUPILAGE_FAILURE = "VERIFY_PUPILAGE_FAILURE";

export const FAILED_PUPILAGE_SUCCESS = "FAILED_PUPILAGE_SUCCESS";
export const FAILED_PUPILAGE_FAILURE = "FAILED_PUPILAGE_FAILURE";
