import {
  APPLY_CHAMBER,
  CHAMBER_CERTIFICATE,
  CHAMBER_PAYMENT,
  FAILED_CHAMBER_PAYMENT,
  GET_CHAMBER,
  GET_CHAMBER_MEMBERS,
  MANUAL_CHAMBER,
  REMOVE_CHAMBER_MEMBER,
  SECOND_CHAMBER_FAIL,
  SECOND_CHAMBER_VERIFY,
  SINGLE_USER_CHAMBER,
  UPDATE_CHAMBER,
  UPLOAD_CHAMBER_FILES,
  VERIFY_CHAMBER_PAYMENT,
} from "../../constants/api_constants";
import { handleApi, handleFileUpload } from "../utils";

export const getUserChamber = async () => {
  return handleApi("get", GET_CHAMBER);
};

export const applyChamber = async (values) => {
  return handleApi("post", APPLY_CHAMBER, values);
};

export const getChamberMember = async (value) => {
  return handleApi("get", `${GET_CHAMBER_MEMBERS}/${value}`);
};

export const verifyChamberPayment = async (values) => {
  return handleApi("put", VERIFY_CHAMBER_PAYMENT, values);
};

export const failedChamberPayment = async (values) => {
  return handleApi("put", FAILED_CHAMBER_PAYMENT, values);
};

export const addChamberMember = async (values) => {
  return handleApi("post", `${GET_CHAMBER_MEMBERS}/${values.id}`, values);
};

export const removeChamberMember = async (value) => {
  return handleApi("delete", `${REMOVE_CHAMBER_MEMBER}/${value}`);
};

export const uploadRGD = async (id, value) => {
  return handleFileUpload(`${UPLOAD_CHAMBER_FILES}/rgd/${id}`, value);
};

export const uploadVAT = async (id, value) => {
  return handleApi("put", `${UPLOAD_CHAMBER_FILES}/vat/${id}`, value);
};

export const uploadSSNIT = async (id, value) => {
  return handleApi("put", `${UPLOAD_CHAMBER_FILES}/ssnit/${id}`, value);
};

export const uploadEvidence = async (id, value) => {
  return handleApi("put", `${UPLOAD_CHAMBER_FILES}/evidence/${id}`, value);
};

export const updateChamber = async (values) => {
  return handleApi("put", `${UPDATE_CHAMBER}/${values.id}`, values);
};

export const singleUserChamber = async (value) => {
  return handleApi("get", `${SINGLE_USER_CHAMBER}/${value}`);
};

export const chamberCert = async (values) => {
  return handleApi("post", CHAMBER_CERTIFICATE, values);
};

export const chamberPayment = async (values) => {
  return handleApi("put", CHAMBER_PAYMENT, values);
};

export const manualChamber = async (values) => {
  return handleApi("post", MANUAL_CHAMBER, values);
};

export const secondChamberVerify = async (values) => {
  return handleApi("put", SECOND_CHAMBER_VERIFY, values);
};

export const secondChamberFail = async (values) => {
  return handleApi("put", SECOND_CHAMBER_FAIL, values);
};
