import React, { useMemo } from "react";
import Divider from "../../../common/shared/Divider";
import VerificationButton from "../../../common/buttons/VerificationButton";
import checkYear from "../../../utils/yearCheck";
import regions from "../../../common/shared/regions";
import {
  normalDues,
  secondDuesFail,
  secondDuesVerify,
} from "../../../redux/actions/dues_action";
import ReceiptButton from "../../../common/buttons/ReceiptButton";
import DuesPayment from "./DuesPayment";
import ManualPaymentButton from "./ManualPaymentButton";
import UpdatePaymentButton from "./UpdatePaymentButton";
import RegionDuesButton from "./RegionDuesButton";

function DuesPaymentPage({ data }) {
  const userData = JSON.parse(localStorage.getItem("profile")).user;

  const key = "sk_live_aee49e82f74c08ca7858ba553b21eaa41d20a888";

  let receiptCheck = 0;
  let getReceipt;

  const dataAmount = Number(data?.amount);
  const receiptAmount = Number(getReceipt?.amount);

  if (
    !isNaN(dataAmount) &&
    !isNaN(receiptAmount) &&
    dataAmount > receiptAmount
  ) {
    receiptCheck = dataAmount - receiptAmount;
  }

  const combineRegion = regions.map((region) => ({
    ...region,
    amount: data.meta.regional[region.id],
  }));

  const userYears = useMemo(
    () => checkYear(userData.date_of_call),
    [userData.date_of_call]
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
      <div>
        <Divider title="National Dues" variant="h5" />
        <div className="flex justify-between">
          <div className="flex-1">National Dues </div>
          <div className="flex-1 ml-4">
            <div className="flex justify-between">
              <div className="flex-1">GH¢{parseInt(data?.amount)}</div>
              <div className="flex-1 ml-4">
                <div className="flex-1 ml-4">
                  {data?.receipts?.some(
                    (item) =>
                      item.post_type === "dues" && item.status === 2 && item.url
                  ) ? (
                    <VerificationButton
                      reference={
                        data?.receipts?.find(
                          (item) => item.post_type === "dues"
                        )?.url
                      }
                      serial={key}
                      apiFxn={secondDuesVerify}
                      id={
                        data.receipts.find((item) => item.post_type === "dues")
                          .id
                      }
                      apiFail={secondDuesFail}
                      productId={
                        data.receipts.find((item) => item.post_type === "dues")
                          .product_id
                      }
                    />
                  ) : data?.receipts?.some(
                      (item) => item.post_type === "dues" && item.status === 1
                    ) ? (
                    <ReceiptButton
                      id={
                        data?.receipts?.find(
                          (item) => item.post_type === "dues"
                        )?.id || null
                      }
                    />
                  ) : (
                    <div className="flex w-max gap-4">
                      <DuesPayment
                        data={data}
                        amount={data?.amount}
                        year={data?.year}
                        id={data?.id}
                        post_type="dues"
                      />
                      <ManualPaymentButton
                        amount={data?.amount}
                        year={data?.year}
                        id={data?.id}
                        post_type="dues"
                        apiFxn={normalDues}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {receiptCheck > 0 && (
          <div className="flex justify-between my-4">
            <div className="flex-1">OutStanding Payment </div>
            <div className="flex-1 ml-4">
              <div className="flex justify-between">
                <div className="flex-1">GH¢{receiptCheck}</div>
                <div className="flex-1 ml-4">
                  <div className="flex-1 ml-4">
                    <div className="flex w-max gap-4">
                      <UpdatePaymentButton
                        data={data}
                        amount={data?.amount}
                        id={data.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Divider title="Regional Dues" variant="h5" />

        <div className="my-4">
          {combineRegion.map((region, index) => (
            <div key={index} className="flex justify-between my-4">
              <div className="flex-1">{region.name} Region</div>
              <div className="flex-1 ml-4">
                <div className="flex justify-between">
                  <div className="flex-1">GH¢{parseInt(region?.amount)}</div>
                  <div className="flex-1 ml-4">
                    {data?.receipts?.some(
                      (item) =>
                        item.post_type === "regdues" &&
                        item.status === 2 &&
                        item.sub_type === region.name
                    ) ? (
                      <VerificationButton
                        reference={
                          data?.receipts?.find(
                            (item) =>
                              item.post_type === "regdues" &&
                              item.sub_type === region.name
                          )?.url
                        }
                        serial={key}
                        apiFxn={secondDuesVerify}
                        id={
                          data?.receipts?.find(
                            (item) =>
                              item.post_type === "regdues" &&
                              item.sub_type === region.name
                          )?.id
                        }
                        apiFail={secondDuesFail}
                        productId={
                          data?.receipts?.find(
                            (item) =>
                              item.post_type === "regdues" &&
                              item.sub_type === region.name
                          )?.product_id
                        }
                      />
                    ) : data?.receipts?.some(
                        (item) =>
                          item.post_type === "regdues" &&
                          item.status === 1 &&
                          item.sub_type === region.name
                      ) ? (
                      <ReceiptButton
                        id={
                          data?.receipts?.find(
                            (item) =>
                              item.post_type === "regdues" &&
                              item.sub_type === region.name
                          )?.id || null
                        }
                      />
                    ) : (
                      <div className="flex w-max gap-4">
                        <RegionDuesButton
                          data={data}
                          amount={region?.amount}
                          year={data?.year}
                          id={data?.id}
                          post_type="regdues"
                          sub_type={region?.name}
                        />
                        <ManualPaymentButton
                          amount={region?.amount}
                          year={data?.year}
                          id={data?.id}
                          post_type="regdues"
                          apiFxn={normalDues}
                          sub_type={region.name}
                          check={data?.receipts?.some(
                            (item) =>
                              item.post_type === "regdues" &&
                              item.sub_type === region.name
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Divider title="Optional Dues" variant="h5" />

        {data?.meta?.forums?.all && (
          <div className="flex justify-between mb-4">
            <div className="flex-1">Forum Dues </div>
            <div className="flex-1 ml-4">
              <div className="flex justify-between">
                <div className="flex-1">GH¢{data?.meta?.forums?.all}</div>
                <div className="flex-1 ml-4">
                  <div className="flex-1 ml-4">
                    {data?.receipts?.some(
                      (item) =>
                        item.post_type === "inhouse_forum" && item.status === 1
                    ) ? (
                      <ReceiptButton
                        id={
                          data?.receipts?.find(
                            (item) => item.post_type === "inhouse_forum"
                          )?.id || null
                        }
                      />
                    ) : (
                      <div className="flex w-max gap-4">
                        <DuesPayment
                          amount={data?.meta?.forums?.all}
                          year={data.year}
                          id={data.id}
                          post_type="inhouse_forum"
                        />

                        <ManualPaymentButton
                          amount={data?.meta?.forums?.all}
                          year={data?.year}
                          id={data?.id}
                          post_type="inhouse_forum"
                          apiFxn={normalDues}
                          check={data?.receipts?.some(
                            (item) => item.post_type === "inhouse_forum"
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {data?.meta?.women && (
          <div className="flex justify-between mb-4">
            <div className="flex-1">Women Dues </div>
            <div className="flex-1 ml-4">
              <div className="flex justify-between">
                <div className="flex-1">
                  GH¢
                  {userYears
                    ? data?.meta?.women?.ten_or_more
                    : data?.meta?.women?.less_than_ten}
                </div>
                <div className="flex-1 ml-4">
                  <div className="flex-1 ml-4">
                    {data?.receipts?.some(
                      (item) => item.post_type === "women" && item.status === 1
                    ) ? (
                      <ReceiptButton
                        id={
                          data?.receipts?.find(
                            (item) => item.post_type === "women"
                          )?.id || null
                        }
                      />
                    ) : (
                      <div className="flex w-max gap-4">
                        <DuesPayment
                          amount={
                            userYears
                              ? data?.meta?.women?.ten_or_more
                              : data?.meta?.women?.less_than_ten
                          }
                          year={data.year}
                          id={data.id}
                          post_type="women"
                        />

                        <ManualPaymentButton
                          amount={
                            userYears
                              ? data?.meta?.women?.ten_or_more
                              : data?.meta?.women?.less_than_ten
                          }
                          year={data?.year}
                          id={data?.id}
                          post_type="women"
                          apiFxn={normalDues}
                          check={data?.receipts?.some(
                            (item) => item.post_type === "women"
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DuesPaymentPage;
