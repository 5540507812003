import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/payment/receipt_api";
import * as types from "../constants/payment_constants";

const userReceiptTypes = {
  success: types.GET_RECEIPT_SUCCESS,
  failure: types.GET_RECEIPT_FAILURE,
};

const userDuesReceiptTypes = {
  success: types.GET_RECEIPT_SUCCESS,
  failure: types.GET_RECEIPT_FAILURE,
};

export const getUserReceipt = createAsyncAction(
  api.getReceipt,
  userReceiptTypes
);

export const getDuesReceipt = createAsyncAction(
  api.getDuesReceipt,
  userDuesReceiptTypes
);
