import React from "react";
import SectionTitle from "../../../common/shared/SectionTitle";
import { useSelector } from "react-redux";
import AlertMessage from "../../../common/actions/AlertMessage";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import ChamberDetails from "./ChamberDetails";
import ChamberMembers from "./ChamberMembers";
import ChamberFiles from "./ChamberFiles";

const link = [{ name: "Edit Chamber" }];

function EditChamber() {
  const { chamberError } = useSelector((state) => state.chamber);

  const pages = [
    {
      label: "Chamber Details",
      value: "chamber_details",
      page: <ChamberDetails />,
    },
    {
      label: "Chamber Members",
      value: "chamber_members",
      page: <ChamberMembers />,
    },
    { label: "Chamber Files", value: "chamber_files", page: <ChamberFiles /> },
  ];

  return (
    <>
      <SectionTitle title="Edit Chamber" description="" children={link} />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {chamberError && <AlertMessage type="error" message={chamberError} />}

        <Tabs value="chamber_details">
          <TabsHeader>
            {pages.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {pages.map(({ value, page }) => (
              <TabPanel key={value} value={value}>
                {page}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </>
  );
}

export default EditChamber;
