import { handleApi, toastApi } from "../utils";
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_PROFILE,
  LOGIN_API,
  REGISTER_API,
  REGISTER_CHECK,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
} from "../../constants/api_constants";

export const signIn = async (values) => {
  return handleApi("post", LOGIN_API, values);
};

export const signUp = async (values) => {
  return handleApi("post", REGISTER_API, values);
};

export const signUpCheck = async (value) => {
  return handleApi("get", `${REGISTER_CHECK}/${value}`);
};

export const getProfile = async () => {
  return handleApi("get", GET_PROFILE);
};

export const updateProfile = async (values) => {
  return handleApi("put", UPDATE_PROFILE, values);
};

export const changePassword = async (values) => {
  return handleApi("put", CHANGE_PASSWORD, values);
};

export const uploadImage = async (value) => {
  return handleApi("put", UPDATE_PROFILE_IMAGE, value);
};

export const forgortPassword = async (value) => {
  return toastApi(
    "put",
    FORGOT_PASSWORD,
    value,
    "Password reset link successfully sent to email"
  );
};

export const resetPassword = async (values) => {
  return toastApi(
    "put",
    `${RESET_PASSWORD}/${values.id}`,
    values,
    "Password successfully reset. Please login."
  );
};
