import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React, { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  pupilagePayment,
  updatePupilage,
} from "../../../redux/actions/pupilage_action";
import OpenModal from "../../../common/actions/OpenModal";
import DatePickerComp from "../../../common/forms/DatePicker";

function PupilageList({ data }) {
  const [loading, setLoading] = useState(false);
  const [updatePupil, setUpdatePupil] = useState(false);

  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth?.userData);

  const handleDateChange = (date) => {
    setEndDate(date);
  };

  const formData = {
    cert_number: data?.cert_number,
    name: `${user.title} ${user.first_name} ${user.last_name}`,
    start_date: data?.start_date,
    end_date: data?.end_date,
    senior: `${data?.s_name.title} ${data?.s_name.first_name} ${data?.s_name.last_name}`,
    chamber: data?.chamber_name,
  };

  const handlePupilUpdate = () => setUpdatePupil(!updatePupil);

  const newAmount = data.amount * 100;
  const charge = newAmount * 0.02;
  const totalAmount = newAmount + charge;

  let reference;
  const config = {
    reference: reference || new Date().getTime().toString(),
    email: user?.email,
    amount: totalAmount,
    publicKey: "pk_live_2d3f72b8943896c18bec7282d661ba3c34dc4077",
    currency: "GHS",
    metadata: {
      section_id: data.id,
      bar_number: user.bar_number,
      phone: user?.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    dispatch(pupilagePayment(data?.id)).finally(() => {
      setLoading(false);
      setUpdatePupil(false);
    });
  };

  const handleCompletion = () => {
    const formData = {
      id: data?.id,
      p_status: data?.p_status + 1,
      end_date: endDate,
    };

    dispatch(updatePupilage(formData)).finally(() => {
      setLoading(false);
      setUpdatePupil(false);
    });
  };

  const generateCert = () => {
    navigate("/home/pupilage/cert", { state: { formData } });
  };

  const onClose = () => {
    reference = new Date().getTime().toString();
  };

  return (
    <Card>
      <CardBody>
        <span>Status:</span>
        <Typography
          variant="h5"
          color={
            data && data.p_status === 6
              ? data.cert_number
                ? "green"
                : "red"
              : "red"
          }
          className="mb-4"
        >
          {data?.pupils_status?.name}
        </Typography>
        <hr />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
          <div>
            <span>Name</span>
            <Typography variant="h5" color="black">
              {user.title +
                " " +
                user.first_name +
                " " +
                user.last_name +
                " - " +
                user.bar_number}
            </Typography>
          </div>

          <div>
            <span>Senior's Name</span>
            <Typography variant="h5" color="black">
              {data?.s_name?.title +
                " " +
                data?.s_name?.first_name +
                " " +
                data?.s_name?.last_name +
                " - " +
                data?.s_name?.bar_number}
            </Typography>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4">
          <div>
            <span>Chamber Name</span>
            <Typography variant="h5" color="black">
              {data?.chamber_name}
            </Typography>
          </div>

          <div>
            <span>Chamber #</span>
            <Typography variant="h5" color="black">
              {data?.chamber_num}
            </Typography>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-4 mb-10">
          <div>
            <span>Start Date</span>
            <Typography variant="h5" color="black">
              {moment(data?.start_date).format("MMMM Do YYYY")}
            </Typography>
          </div>

          <div>
            <span>Region</span>
            <Typography variant="h5" color="black">
              {data?.region?.name}
            </Typography>
          </div>
        </div>

        {data.rejected && (
          <>
            <span>Rejected Reason</span>
            <Typography variant="h5" color="red">
              {data?.rejected}
            </Typography>
          </>
        )}

        <div className="mt-20">
          <Typography variant="small" className="text-center">
            By clicking to proceed below you affirm that the information and
            data you have provided on this form are true; and that you are not
            knowingly submitting information which is untrue, misleading or
            false.
          </Typography>
        </div>
      </CardBody>
      <CardFooter className="flex justify-center">
        {data?.p_status === 3 ? (
          <Button color="teal" onClick={handlePupilUpdate}>
            Set Date Completion
          </Button>
        ) : data?.p_status < 6 ? (
          <Button
            type="button"
            className="flex items-center gap-3"
            onClick={() => navigate("/home/pupilage/edit")}
          >
            Edit Application
          </Button>
        ) : data?.cert_number ? (
          <Button color="indigo" onClick={() => generateCert()}>
            View Certificate
          </Button>
        ) : (
          // <CertificateButton apiFxn={api} />
          <Button
            onClick={() => initializePayment(onSuccess, onClose)}
            color="pink"
          >
            Make Payment
          </Button>
        )}
      </CardFooter>

      {updatePupil && (
        <OpenModal
          open={updatePupil}
          handler={handlePupilUpdate}
          title="Date of Completion"
          loading={loading}
          actionTitle="Complete Pupillage"
          handleSubmit={() => handleCompletion()}
          children={
            <DatePickerComp
              label=""
              onSelectDate={handleDateChange}
              maxDate={new Date()}
            />
          }
        />
      )}
    </Card>
  );
}

export default PupilageList;
