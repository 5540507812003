import { handleApi } from "../utils";
import {
  FAILED_LICENSE_PAYMENT,
  GET_LICENSE,
  LICENSE_CERTIFICATE,
  LICENSE_PAYMENT,
  MANUAL_LICENSE,
  ORDER_LICENSE_REF,
  SECOND_LICENSE_FAIL,
  SECOND_LICENSE_VERIFY,
  VERIFY_LICENSE_PAYMENT,
} from "../../constants/api_constants";

export const getUserLicense = async () => {
  return handleApi("get", GET_LICENSE);
};

export const licensePayment = async (values) => {
  return handleApi("post", LICENSE_PAYMENT, values);
};

export const licenseCert = async (values) => {
  return handleApi("post", LICENSE_CERTIFICATE, values);
};

export const licenseManual = async (values) => {
  return handleApi("post", MANUAL_LICENSE, values);
};

export const verifyLicense = async (values) => {
  return handleApi("put", VERIFY_LICENSE_PAYMENT, values);
};

export const failedLicense = async (values) => {
  return handleApi("put", FAILED_LICENSE_PAYMENT, values);
};

export const orderLicenseRef = async (values) => {
  return handleApi("post", ORDER_LICENSE_REF, values);
};

export const secondLicenseVerify = async (values) => {
  return handleApi("put", SECOND_LICENSE_VERIFY, values);
};

export const secondLicenseFail = async (values) => {
  return handleApi("put", SECOND_LICENSE_FAIL, values);
};
