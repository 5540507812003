import { Option, Select } from "@material-tailwind/react";
import React, { useState } from "react";

function SelectOption({
  labelTtile,
  defaultValue,
  updateFormValue,
  updateType,
  children,
  containerStyle,
}) {
  const [value, setValue] = useState(defaultValue);

  const updateSelectValue = (val) => {
    setValue(val);
    updateFormValue({ value: val, updateType });
  };

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <Select
        size="lg"
        label={labelTtile}
        onChange={(e) => updateSelectValue(e)}
        value={value}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
      >
        {children.map((item, index) => (
          <Option key={index} value={`${item.id}`}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default SelectOption;
