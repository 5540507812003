import { Button, Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function ManualPaymentButton({
  amount,
  year,
  id,
  post_type,
  check,
  apiFxn,
  name = null,
}) {
  const [manualLoading, setManualLoading] = useState(false);

  const dispatch = useDispatch();

  const showToastMessage = () => {
    toast.success("Manual Payment added successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleManual = () => {
    setManualLoading(true);
    const formData = {
      amount,
      year,
      id,
      post_type,
      name,
    };

    dispatch(apiFxn(formData)).finally(() => {
      setManualLoading(false);
      showToastMessage();
    });
  };

  return (
    <Button
      onClick={() => handleManual()}
      size="sm"
      color="amber"
      disabled={check}
    >
      {manualLoading ? <Spinner /> : "Manual Pay"}
    </Button>
  );
}

export default ManualPaymentButton;
