import { Chip } from "@material-tailwind/react";
import React from "react";

function DuesStatus({ data }) {
  let receiptCheck = false;
  let getReceipt;

  const status =
    data &&
    data?.receipts.some(
      (item) => item.post_type === "dues" && item.status === 1
    );

  if (data?.year === "2023") {
    if (data?.receipts) {
      getReceipt = data?.receipts.find((item) => item.post_type === "dues");
      receiptCheck = getReceipt?.amount !== parseInt(data?.amount);
    }
  }

  return (
    <div className="flex ml-2">
      {data.exempt ? (
        <Chip color="indigo" value="Exempted" variant="ghost" />
      ) : receiptCheck ? (
        <Chip color="pink" value="Outstanding Balance" variant="ghost" />
      ) : status ? (
        <Chip color="green" value="Paid" variant="ghost" />
      ) : (
        <Chip color="amber" value="Not Paid" variant="ghost" />
      )}
    </div>
  );
}

export default DuesStatus;
