const practice_areas = [
  {
    id: 1,
    name: "Administrative Law",
  },
  {
    id: 2,
    name: "Admiralty and Maritime Law",
  },
  {
    id: 3,
    name: "Advertising",
  },
  {
    id: 4,
    name: "Agency, Distribution and Licensing",
  },
  {
    id: 5,
    name: "Antitrust and Competition",
  },
  {
    id: 6,
    name: "Arbitration, Dispute Resolution & Mediation",
  },
  {
    id: 7,
    name: "Aviation and aerospace",
  },
  {
    id: 8,
    name: "Banking Law",
  },
  {
    id: 9,
    name: "Biotechnology",
  },
  {
    id: 10,
    name: "Business Crime",
  },
  {
    id: 11,
    name: "Charities and Foundations",
  },
  {
    id: 12,
    name: "Civil Rights",
  },
  {
    id: 13,
    name: "Commercial Law",
  },
  {
    id: 14,
    name: "Commercial Property",
  },
  {
    id: 15,
    name: "Communications Law",
  },
  {
    id: 16,
    name: "Constitutional Law",
  },
  {
    id: 17,
    name: "Construction Law",
  },
  {
    id: 18,
    name: "Contracts",
  },
  {
    id: 19,
    name: "Corporate Law",
  },
  {
    id: 20,
    name: "Criminal Law",
  },
  {
    id: 21,
    name: "Cultural Property",
  },
  {
    id: 22,
    name: "Customs and Excise",
  },
  {
    id: 23,
    name: "Debt and creditor",
  },
  {
    id: 24,
    name: "Election Campaign & Political",
  },
  {
    id: 25,
    name: "Employment Benefits",
  },
  {
    id: 26,
    name: "Energy",
  },
  {
    id: 27,
    name: "Entertainment and Sports",
  },
  {
    id: 28,
    name: "Environmental Law",
  },
  {
    id: 29,
    name: "European Union Law",
  },
  {
    id: 30,
    name: "Family Law",
  },
  {
    id: 31,
    name: "Finance",
  },
  {
    id: 32,
    name: "Foreign Investment",
  },
  {
    id: 33,
    name: "Franchising",
  },
  {
    id: 34,
    name: "General Practice",
  },
  {
    id: 35,
    name: "Government",
  },
  {
    id: 36,
    name: "Government Contracts",
  },
  {
    id: 37,
    name: "Health and safety",
  },
  {
    id: 38,
    name: "Human Rights Law",
  },
  {
    id: 39,
    name: "Immigration and Nationality",
  },
  {
    id: 40,
    name: "Information Technology (IT) Law",
  },
  {
    id: 41,
    name: "Insolvency, Liquidation & Corporate Rescue",
  },
  {
    id: 42,
    name: "Insurance and Re-Insurance",
  },
  {
    id: 43,
    name: "Intellectual Property Law",
  },
  {
    id: 44,
    name: "International Law",
  },
  {
    id: 45,
    name: "International Trade",
  },
  {
    id: 46,
    name: "Internet Law",
  },
  {
    id: 47,
    name: "Investment Companies, Funds and Trusts",
  },
  {
    id: 48,
    name: "Labour and Employment",
  },
  {
    id: 49,
    name: "Legal Malpractice",
  },
  {
    id: 50,
    name: "Litigation",
  },
  {
    id: 51,
    name: "Medicine and Ethics",
  },
  {
    id: 52,
    name: "Mergers and Acquisitions",
  },
  {
    id: 53,
    name: "Natural Resources",
  },
  {
    id: 54,
    name: "Pensions",
  },
  {
    id: 55,
    name: "Personal Injury",
  },
  {
    id: 56,
    name: "Practice Management and Development",
  },
  {
    id: 57,
    name: "Products Liability",
  },
  {
    id: 58,
    name: "Professional Liability",
  },
  {
    id: 59,
    name: "Project Finance",
  },
  {
    id: 60,
    name: "Real Estate",
  },
  {
    id: 61,
    name: "Securities",
  },
  {
    id: 62,
    name: "Taxation",
  },
  {
    id: 63,
    name: "Technology & Science",
  },
  {
    id: 64,
    name: "Transportation",
  },
  {
    id: 65,
    name: "Trusts and Estates",
  },
  {
    id: 66,
    name: "Zoning, Planning and Land Use",
  },
  {
    id: 67,
    name: "Chieftaincy",
  },
  {
    id: 68,
    name: "National Security Law",
  },
  {
    id: 69,
    name: "Cyber Law",
  },
  {
    id: 70,
    name: "Maritime Law",
  },
];

export default practice_areas;
