import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth_reducer";
import conferenceReducer from "./conference_reducer";
import licenseReducer from "./license_reducer";
import pupilageReducer from "./pupilage_reducer";
import duesReducer from "./dues_reducer";
import chamberReducer from "./chamber_reducer";
import paymentReducer from "./payment_reducer";
import glcReducer from "./glc_reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  conference: conferenceReducer,
  license: licenseReducer,
  pupilage: pupilageReducer,
  dues: duesReducer,
  chamber: chamberReducer,
  payment: paymentReducer,
  glc: glcReducer,
});

export default rootReducer;
