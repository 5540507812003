import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import React from "react";

function OpenModal({
  open,
  handler,
  title,
  children,
  loading,
  handleSubmit,
  actionTitle,
  size = "md",
}) {
  return (
    <Dialog open={open} size={size} handler={handler}>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{children}</DialogBody>
      <DialogFooter>
        <Button variant="text" color="red" onClick={handler} className="mr-1">
          Cancel
        </Button>

        {actionTitle && (
          <Button variant="gradient" onClick={handleSubmit}>
            {loading ? <Spinner className="h-4 w-4" /> : actionTitle}
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
}

export default OpenModal;
