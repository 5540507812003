const checkYear = (year) => {
  const date = new Date(year);
  const currentDate = new Date();

  const timeDifference = currentDate - date;
  const yearInMilliseconds = 7 * 365 * 24 * 60 * 60 * 1000;

  return timeDifference > yearInMilliseconds;
};

export default checkYear;
