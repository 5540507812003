import { Breadcrumbs, Button, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function SectionTitle({
  title,
  children = [],
  description,
  requirement = false,
  details,
  link,
}) {
  const navigate = useNavigate();
  const handleNavigate = () => navigate(link);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <Button
        variant="outlined"
        className="flex items-center gap-2 mb-8"
        onClick={() => (link ? handleNavigate() : navigate(-1))}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
          />
        </svg>
        Go Back
      </Button>
      <Typography variant="h3" color="black">
        {title}
      </Typography>
      <Typography variant="small" className="my-2">
        {description}
      </Typography>
      {requirement && details}
      <Breadcrumbs separator="-" className="mt-8">
        <a href="/home" className="opacity-60">
          Home
        </a>
        {children.map((item, index) => (
          <a key={index} href={item.link} className="opacity-60">
            {item.name}
          </a>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default SectionTitle;
