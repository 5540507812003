import { Typography } from "@material-tailwind/react";
import React from "react";

function Divider({ title, color = "black", variant }) {
  return (
    <div className="flex items-center my-8">
      <Typography color={color} variant={variant ? variant : null}>
        {title}
      </Typography>

      <div className={`flex-grow border-t ${color}`}></div>
    </div>
  );
}

export default Divider;
