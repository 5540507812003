import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";

function PupilgeDialog({
  modal,
  senior,
  chamber,
  region,
  start,
  fxn,
  loading,
}) {
  const [open, setOpen] = useState(modal);

  return (
    <Dialog
      open={open}
      animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}
    >
      <DialogHeader>Confirm Details</DialogHeader>
      <DialogBody>
        <Typography color="black" variant="paragraph">
          Seniors Name: <strong>{senior}</strong>{" "}
        </Typography>
        <Typography color="black" variant="paragraph">
          Chamber Name: <strong>{chamber}</strong>
        </Typography>
        <Typography color="black" variant="paragraph">
          Region: <strong>{region}</strong>
        </Typography>
        <Typography color="black" variant="paragraph">
          Start Date: <strong>{start}</strong>
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          className="mr-1"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button type="button" className="flex items-center gap-3" onClick={fxn}>
          {loading && <Spinner color="white" />}
          Proceed
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default PupilgeDialog;
